import React, { useState, useEffect } from 'react';
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import { Link } from 'react-router-dom';
import { FiUser } from "react-icons/fi";
import { FaRegCalendarAlt } from "react-icons/fa";
import { RiArrowRightLine } from "react-icons/ri";
import { windowScroll } from '../components/utils/windowScroll';
import axios from 'axios';
import SEO from '../components/shared/seo';

const Blog = () => {
  // const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 4;
  // const totalItems = blogsData.length;
  // const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [allBlogs, setAllBlogs] = useState([]);

  const removeHTMLTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const fetchBlogs = async () => {
    const response = await axios.get(`${process.env.REACT_APP_URL}user/getblog`);
    const filteredData = response.data.data.filter(item => item.isActive === true);
    const sortedBlogs = filteredData.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));
    const latest4Blogs = sortedBlogs.slice(0, 4);
      console.log("latest4Blogs", latest4Blogs)
    setAllBlogs(sortedBlogs);
  };

  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };


  // const handlePrevPage = () => {
  //   setCurrentPage(currentPage - 1);
  // };

  useEffect(() => {
    fetchBlogs();
  }, []);

  // const showNext = () => {
  //   const nextIndex = currentPage * itemsPerPage;
  //   const nextItems = allBlogs.slice(nextIndex, nextIndex + itemsPerPage);
  //   setAllBlogs(nextItems);
  // };

  return (
    <>
    <SEO title='Stay Updated with My Internet Services Blog' description='Find The latest tech trends, tips, and insights on My Internet Blog. Stay informed and enhance your digital knowledge with our expert articles.'/>
    <Layout>
      <InnerPageBanner title='Blogs' innerPageBanner={ImageIcons.contactBg} />
      <section>
        <div className="container">
          <div className='max-w-[800px] md:mb-[50px] mb-[40px] text-center mx-auto'>
            <div className='text-[#f48533] uppercase mb-[5px] '>Blogs Post</div>
            <h2>Our <span className='text-[#f48533]'>Recent</span> Update </h2>
          </div>
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-[30px] sm:gap-[20px] lg:gap-[20px] '>
            {allBlogs.map((item, i) => (
              <Link key={i} to={`/blogs/${item.handle}`} onClick={windowScroll} className='flex flex-col overflow-hidden group border rounded-[10px] duration-500 shadow-md hover:shadow-[0px_4px_25px_rgba(0,0,0,0.12)] justify-start'>
                <div className='relative min-h-[230px] w-full overflow-hidden group-hover:after:absolute after:content-[""] after:w-full after:h-full after:left-0 after:top-0  after:bg-[linear-gradient(157deg,#f48533_0%,#3308d3_100%)] after:duration-500 after:opacity-50'>
                  <img
                    src={item?.image}
                    alt={item?.alttag} 
                    className='w-full h-full object-center object-cover duration-500 group-hover:rotate-[5deg] group-hover:scale-150 '
                  />
                </div>
                <div className='p-[25px]'>
                  <ul className='mb-[20px] flex items-center'>
                    <li className='flex items-center text-[15px] mr-[15px]'><FiUser className='inline-block text-[#f48533] mr-[8px]' /> {item?.author}</li>
                    <li className='flex items-center text-[15px]'><FaRegCalendarAlt className='inline-block text-[#f48533] mr-[8px]' /> {item?.publishedAt}</li>
                  </ul>
                  <div>
                    <h4 className='text-[20px] mb-[15px] group-hover:text-[#f48533] duration-500 font-semibold leading-[28px]'>{item?.title}</h4>
                    <p className='mb-[15px] relative line-clamp-3'>{removeHTMLTags(item?.description)}</p>
                    <span className='text-[#000] group-hover:text-[#f48533] duration-500'>Read More <RiArrowRightLine className='inline-block' /></span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
         


          {/* <ul className="pagination flex items-center justify-center mt-[40px] gap-[5px]">
            <button onClick={handlePrevPage} disabled={currentPage === 1} className={`flex items-center justify-center h-[50px] w-[50px] border border-[#f3843285] text-[#f38432] hover:[#f38432] text-[16px] ${currentPage === 1 ? 'opacity-50' : 'hover:bg-[#f38432] hover:text-white duration-500'}`}><RiArrowLeftLine /></button>
            {Array.from({ length: totalPages }, (_, i) => (
              <li key={i} className={`page-item hover:bg-[#f38432] text-[#f38432] hover:text-white duration-500 ${currentPage === i + 1 ? 'active bg-[#f38432] text-white' : ''}`}>
                <button onClick={() => handlePageChange(i + 1)} className="flex items-center justify-center h-[50px] w-[50px] border border-[#f3843285] hover:[#f38432] text-[16px]">{i + 1}</button>
              </li>
            ))}
            <button onClick={showNext} className={`flex items-center justify-center h-[50px] w-[50px] border border-[#f3843285] text-[#f38432] hover:[#f38432] text-[16px] ${currentPage === 1 ? 'hover:bg-[#f38432] hover:text-white duration-500' : 'opacity-50'}`}> <RiArrowRightLine /></button>
           
          </ul> */}
        </div>
      </section>
    </Layout>
    </>
  )
};

export default Blog
