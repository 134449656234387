import React, { useState } from 'react';
import startingPrice from '../assets/images/starting_price.webp';
import { validateEmail } from "../utils/formFunctions";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Loader from "../components/shared/loader";
import Aboutaa from "../assets/images/Aboutaa.png";
import Homefiber from "../assets/images/Homefiber.png";
import Fibertohome from "../assets/images/Fiber to home white.png"
import signal from "../assets/images/signal.png"
import ottwhite from "../assets/images/ott white.png"
import enterprice from "../assets/images/Enterprise white.png"
import highfiber from "../assets/images/router_12669603.png"
import whiteimg from "../assets/images/Mask group2.png"
import digitaltransformation from "../assets/images/digital-transformation_17323355.png"
import { windowScroll } from "../components/utils/windowScroll";

const InquiryForm = () => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState({
    email: false,
    phone: false,
    message: "",
    success: false,
  });

  const [formInput, setFormInput] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    address: "",
    city: ""
  });

  const firstNameHandler = (e) => {
    const inputValue = e.target.value;
    const alphaValue = inputValue.replace(/[^A-Za-z ]/g, ""); // Alphabatical charcter should be allowed
    // Capitalize the first letter of each word
    const capitalizedValue = alphaValue.replace(/\b\w/g, (match) =>
      match.toUpperCase()
    );
    if (capitalizedValue.length <= 30) {
      setFormInput((prevState) => ({
        ...prevState,
        name: capitalizedValue,
      }));
      setError((prevState) => ({ ...prevState, message: "" }));
    }
  };

  const handleSubjectInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 50) {
      setFormInput((prevState) => ({ ...prevState, subject: inputValue }));
      setError((prevState) => ({ ...prevState, message: "" }));
    }
  };

  const handlePhoneInputChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, ""); // Remove non-digit characters

    if (numericValue.length <= 10) {
      setFormInput((prevState) => ({ ...prevState, phone: numericValue }));
      setError((prevState) => ({ ...prevState, phone: false, message: "" }));
    }
    const repeatingDigitsRegex = /(\d)\1{9,}/;
    if (repeatingDigitsRegex.test(numericValue)) {
      // If repeating digits are found, display an error message
      setError((prevState) => ({ ...prevState, phone: true, message: "Phone number cannot consist of repeating digits." }));
    }
  };
//   const validRegex = /^(?!.*(\d)\1{9})[6-9]\d{9}$/;

  const submissionHandler = (e) => {
    e.preventDefault();
    setDisabled(true);
    setError((prevState) => ({ ...prevState, message: "" }));
    if (
      !formInput.name ||
      !formInput.phone ||
      !formInput.email ||
      !formInput.subject ||
      !formInput.message ||
      !formInput.address ||
      !formInput.city
    ) {
      setError((prevState) => ({
        ...prevState,
        success: false,
        message: "Fields must not be empty!",
      }));
      setDisabled(false);
    } else if (!validateEmail(formInput.email)) {
      setError((prevState) => ({
        ...prevState,
        email: true,
        message: "Email is invalid!",
      }));
      setDisabled(false);
    } else if (formInput.phone.length < 10) {
        setError((prevState) => ({
          ...prevState,
          phone: true,
          message: "Phone number is invalid!",
        }));
        setDisabled(false);
      } else if (/^(\d)\1+$/.test(formInput.phone)) {
        setError((prevState) => ({
          ...prevState,
          phone: true,
          message: "Phone number must be valid !",
        }));
        setDisabled(false);
      }else {  
      axios.post(`${process.env.REACT_APP_URL}user/addinquiry`, {
        name: formInput.name,
        email: formInput.email,
        phone: formInput.phone,
        message: formInput.message,
        subject: formInput.subject,
        address: formInput.address,
        city: formInput.city
      })
        .then((response) => {
          console.log("Response:", response.data);
          setError((prevState) => ({ ...prevState, success: true }));
          setTimeout(() => {
            setError((prevState) => ({ ...prevState, success: false }));
            setDisabled(false);
            setFormInput({
              name: "",
              email: "",
              message: "",
              phone: "",
              subject: "",
              address: "",
              city: ""
            });
            navigate("/thanks");
            // localStorage.setItem('currentPathname', pathname);

            windowScroll();
          },);
        })
        .catch((error) => {
          console.error("Error:", error);
          setDisabled(false);
        });
    }
  };

  return (
    <> 
        <section className='bg-[#f87024] md:py-[15px] py-[50px]'>
          <div className='container !max-w-[1250px] '>
            <div className='flex max-md:flex-col md:bg-[#d6d6d6]'>
              <div className='md:w-[68%] max-md:hidden'>
                <img src={startingPrice} alt='Starting Price' className='h-full object-cover object-left' />
              </div>
              <div className='md:w-[32%] w-full max-w-[416px] mx-auto p-[20px] md:m-[15px] bg-white rounded-[10px] relative'>
                <>
                  {disabled && <Loader />}
                  <form className=' ' onSubmit={submissionHandler}>
                    <h1 className='font-extrabold lg:text-[36px] md:text-[30px] text-[36px] mb-[15px] max-md:text-center'>Get In Touch</h1>
                    <p
                      className={`text-[14px] text-left text-[#FF0000] font-semibold mb-0 sm:col-span-2 col-span-1 ${error.message ? "block" : "hidden "}`}
                    >
                      {error.message}
                    </p>
                    <div className='mb-[15px]'>
                      <input placeholder='Name'
                        value={formInput.name}
                        disabled={disabled}
                        onChange={(e) => firstNameHandler(e)}
                        className='lg:px-[25px] px-[20px] h-[48px] bg-[#f0f1f2] w-full rounded-[8px] text-[14px]' />
                    </div>
                    <div className='mb-[15px]'>
                      <input placeholder='Email'
                        value={formInput.email}
                        disabled={disabled}
                        onChange={(e) => {
                          setFormInput((prevState) => ({
                            ...prevState,
                            email: e.target.value,
                          }));
                          setError((prevState) => ({
                            ...prevState,
                            email: false,
                            message: "",
                          }));
                        }}
                        className='lg:px-[25px] px-[20px] h-[48px] bg-[#f0f1f2] w-full rounded-[8px] text-[14px]' />
                    </div>

                    <div className='mb-[15px]'>
                      <input placeholder='Phone Number'
                        value={formInput.phone}
                        disabled={disabled}
                        onChange={(e) => handlePhoneInputChange(e)}
                        maxLength={10}
                        className='lg:px-[25px] px-[20px] h-[48px] bg-[#f0f1f2] w-full rounded-[8px] text-[14px]' />
                    </div>

                    <div className='mb-[15px]'>
                      <input placeholder='Address'
                        value={formInput.address}
                        disabled={disabled}
                        onChange={(e) => {
                          setFormInput((prevState) => ({
                            ...prevState,
                            address: e.target.value,
                          }));
                          setError((prevState) => ({
                            ...prevState,
                            address: false,
                            message: "",
                          }));
                        }}
                        className='lg:px-[25px] px-[20px] h-[48px] bg-[#f0f1f2] w-full rounded-[8px] text-[14px]' />
                    </div>

                    <div className='mb-[15px]'>
                      <input placeholder='City'
                        value={formInput.city}
                        disabled={disabled}
                        onChange={(e) => {
                          setFormInput((prevState) => ({
                            ...prevState,
                            city: e.target.value,
                          }));
                          setError((prevState) => ({
                            ...prevState,
                            city: false,
                            message: "",
                          }));
                        }}
                        className='lg:px-[25px] px-[20px] h-[48px] bg-[#f0f1f2] w-full rounded-[8px] text-[14px]' />
                    </div>

                    <div className='mb-[15px]'>
                      <input placeholder='Subject'
                        value={formInput.subject}
                        disabled={disabled}
                        onChange={(e) => handleSubjectInputChange(e)}
                        className='lg:px-[25px] px-[20px] h-[48px] bg-[#f0f1f2] w-full rounded-[8px] text-[14px]' />
                    </div>
                    
                    <div className='mb-[15px]'>
                      <textarea id="message"
                        error={error.msg}
                        rows="4"
                        value={formInput.message}
                        disabled={disabled}
                        onChange={(e) => {
                          setFormInput((prevState) => ({
                            ...prevState,
                            message: e.target.value,
                          }));
                          setError((prevState) => ({
                            ...prevState,
                            msg: false,
                            message: "",
                          }));
                        }}
                        className="block lg:px-[25px] px-[20px] py-[15px] h-[130px] bg-[#f0f1f2] w-full rounded-[8px] text-[14px]" placeholder="Message"></textarea>
                    </div>
                    <button type='submit' className='px-[20px] text-[14px] h-[45px] w-full duration-500 text-[#fff] bg-[#f48533] rounded-[10px] hover:bg-[#000] inline-block font-semibold'>
                      Send Message
                    </button>
                  </form>
                </>
              </div>
            </div>
          </div>
        </section>

        <div className="lg:max-w-7xl max-w-lg mx-auto px-6 py-8 bg-white ">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
            <div className="">
              <img
                src={Aboutaa}
                alt="Aboutaa"
                className=" object-cover w-full h-full  "
              />
            </div>
            <div>
              <h2 className="text-3xl font-bold  mb-4">ABOUT MY INTERNET</h2>
              <p className="text-gray-600 text-sm leading-6 ">
                MY INTERNET is a reliable and high-quality broadband provider,
                offering you a stable and uninterrupted connection for seamless
                online experiences. We ensure the integrity of our network,
                delivering consistent and dependable service so you can enjoy
                uninterrupted browsing.
              </p>
              <h2 className="text-xl font-bold  mt-8">
                Services Are Providing To You From MY INTERNET?
              </h2>
              <ul className="list-disc text-md text-gray-600 space-y-2 pl-4 mt-6">
                <li>High-speed Fiber Internet Connection</li>
                <li>Reliable and Strong Bandwidth</li>
                <li>No Buffering for Smooth Streaming</li>
                <li>Affordable Internet Plans Tailored to Your Needs</li>
                <li>Unlimited Data Usage</li>
                <li>24/7 Customer Support for Assistance</li>
              </ul>
            </div>
          </div>
        </div>

        <section className='lg:py-24 py-15 px-[15px]'>
          <div className="max-w-7xl mx-auto">
            <h2 className="text-gray-800 sm:text-4xl text-2xl font-extrabold text-center mb-8 ">
              Discover Our Services
            </h2>
            <p className="text-gray-800 sm:text-md text-md  text-center mb-8 max-w-[640px]  mx-auto">
              My Internet broadband service providers are known for delivering a
              wide range of services in order to meet the diverse needs of our
              customers. Our services include
            </p>
            <div className="grid lg:grid-cols-4 md:grid-cols-2 lg:gap-12 gap-8 p-4 sm:p-0 ">
              <div className="text-center rounded-xl  p-8  border  shadow-xl transition duration-400  ease-in-out hover:bg-[linear-gradient(157deg,#3308d3_0%,#f48533_100%)]  group">
                <img
                  src={Homefiber}
                  className=" mb-6 inline-block max-h-32 group-hover:hidden"
                  alt="Homefiber"
                />
                <img
                  src={Fibertohome}
                  
                  className=" mb-6  max-h-32 hidden group-hover:inline-block"
                  alt="Fibertohome"
                />
                <h3 className="text-gray-800 text-xl font-semibold mb-3 group-hover:text-white ">
                  Fiber to The Home
                </h3>
                <p className="text-gray-600 text-sm  px-8 sm:px-0 text-justify  group-hover:text-white ">
                  Enjoy ultra-fast, reliable internet with Fiber to the Home,
                  delivering seamless streaming, gaming, and browsing with
                  unparalleled speed and connectivity.
                </p>
              </div>
              <div className="text-center  rounded-xl group md:p-8 p-4  border  shadow-xl transition duration-300 hover:bg-[linear-gradient(157deg,#3308d3_0%,#f48533_100%)] ">
                <img
                  src={highfiber}
                  
                  className=" mb-6 inline-block max-h-32 group-hover:hidden"
                  alt=""
                />
                <img
                  src={whiteimg}
                  
                  className=" mb-6  max-h-32 hidden group-hover:inline-block"
                  alt="whiteimg"
                />
                <h3 className="text-gray-800 text-xl font-semibold mb-3 group-hover:text-white">
                  High Speed Fiber Internet
                </h3>
                <p className="text-gray-600 text-sm  px-8 sm:px-0 text-justify group-hover:text-white">
                  High-Speed Fiber Internet and downloads smooth streaming with
                  Experience lightning-fast , offering unmatched reliability and
                  performance for all your online needs.
                </p>
              </div>
              <div className="text-center  rounded-xl group md:p-8 p-4  border  shadow-xl transition duration-300 hover:bg-[linear-gradient(157deg,#3308d3_0%,#f48533_100%)]">
                <img
                  src={signal}
                  
                  className=" mb-6 inline-block max-h-32 group-hover:hidden"
                  alt=""
                />
                <img
                  src={ottwhite}
                  
                  className=" mb-6  max-h-32 hidden group-hover:inline-block"
                  alt="ottwhite"
                />
                <h3 className="text-gray-800 text-xl font-semibold mb-3 group-hover:text-white">
                  IPTV, OTT
                </h3>
                <p className="text-gray-600 text-sm px-8 sm:px-0 text-justify group-hover:text-white">
                  Enjoy limitless entertainment with IPTV and OTT services,
                  delivering your favorite shows, movies, and live TV directly over
                  high-speed internet.
                </p>
              </div>
              <div className="text-center  rounded-xl group md:p-8 p-4  border  shadow-xl transition duration-300 hover:bg-[linear-gradient(157deg,#3308d3_0%,#f48533_100%)]">
                <img
                  src={digitaltransformation}
                 
                  className=" mb-6 inline-block max-h-32 group-hover:hidden"
                  alt="digitaltransformation"
                />
                <img
                  src={enterprice}
                  
                  className=" mb-6  max-h-32 hidden group-hover:inline-block"
                  alt="enterprice"
                />
                <h3 className="text-gray-800 text-xl font-semibold mb-3 group-hover:text-white">
                  Leased Line Service
                </h3>
                <p className="text-gray-600 text-sm px-8 sm:px-0 text-justify group-hover:text-white">
                  Accelerate your business with reliable, secure, and efficient
                  leased line solutions. Boost productivity and growth with advanced
                  connectivity and communication tools.
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="bg-gradient-to-r from-[#F68343] via-[#F68343] to-[#F96C1E] py-20  font-[sans-serif]  ">
        <div className=" flex  sm:flex-row flex-col container justify-between  w-full mx-auto  sm:px-48  gap-8 ">
          <div className="text-center">
            <img
              src="https://www.myinternet.services/static/media/mail-icon.91ea8185d96ce472a990.webp"
              className="sm:w-60 w-40 shrink-0 inline-block"
              alt=""
              
            />
          </div>
          <div className="container mx-auto text-center">
            <h2 className="text-4xl font-bold text-white mb-6">GET IN TOUCH</h2>
            <p className="text-lg text-white mb-12">
              Check all the available plans in your locality.
            </p>
            <a
              href="/contact-us"
              className="bg-white text-[#F68343] hover:bg-[#363634] hover:text-white py-3 px-8 rounded-full text-lg font-semibold transition duration-300 hover:shadow-lg"
            >
              Get Started
            </a>
          </div>{" "}
          <div className="text-center">
            <img
              src="https://www.myinternet.services/static/media/send-icon.6c43f7b8dc0aab6fe209.webp"
              className="sm:w-60 w-40 shrink-0 inline-block"
              alt=""
              
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default InquiryForm
