import React, { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { MdSearchOff } from "react-icons/md";
import axios from "axios";

const Search = () => {
    const [searchTerm, setSearchTerm] = useState(""); // User's input
    const [search, setSearch] = useState([]); // List of cities from the backend
    const [filteredCities, setFilteredCities] = useState([]); // Filtered suggestions
    const navigate = useNavigate();

    // Fetch city data on component load
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}user/getcities`);
                setSearch(response?.data?.data || []);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    // Filter cities dynamically as the user types
    const handleInputChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);

        // Filter cities matching the input
        const filteredItems = search.filter((city) =>
            city?.cityname?.toLowerCase().includes(term.toLowerCase())
        );
        setFilteredCities(filteredItems);
    };

    // Navigate to the city's path when the search button is clicked or Enter is pressed
    const handleSearch = () => {
        if (searchTerm.trim() !== "") {
            // Find the matching city
            const matchedCity = search.find(
                (city) => city?.cityname?.toLowerCase() === searchTerm.toLowerCase()
            );

            if (matchedCity) {
                // Navigate to the specific city's path
                const formattedCityName = matchedCity.handle.replace(/\s+/g, "-").toLowerCase();
                navigate(`/${formattedCityName}`);
                setSearchTerm(""); // Clear input
                setFilteredCities([]); // Clear dropdown
            }
        }
    };

    // Handle "Enter" key press
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Prevent form submission
            handleSearch(); // Trigger search
        }
    };

    return (
        <div className="pt-20 pb-[20px]">
            <div className="container !max-w-[900px] mx-auto">
                <div className="flex w-full relative">
                    <input
                        type="text"
                        id="search-dropdown"
                        value={searchTerm}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        className="border border-[#b8b8b8] focus:outline-none bg-[#fff] text-[#000] w-full px-[20px] h-[50px]"
                        placeholder="Search for your state"
                    />

                    {searchTerm && (
                        <div className="absolute rounded shadow-[0_5px_10px_5px_rgba(0,0,0,0.1)] bg-white z-20 mt-[5px] w-full top-full">
                            {filteredCities.length === 0 ? (
                                <ul>
                                    <li>
                                        <div className="px-[20px] h-[50px] border-b flex items-center">
                                            <MdSearchOff className="h-5 w-6 cursor-pointer" />
                                            Not Found
                                        </div>
                                    </li>
                                </ul>
                            ) : (
                                <ul>
                                    {filteredCities.map((city) => (
                                        <li key={city?._id} className="w-full text-center">
                                            <div
                                                className="px-[20px] h-[50px] border-b flex items-center"
                                                onClick={() => {
                                                    const formattedCityName = city?.handle
                                                        .replace(/\s+/g, "-")
                                                        .toLowerCase();
                                                    navigate(`/${formattedCityName}`);
                                                    setSearchTerm(""); // Clear input
                                                    setFilteredCities([]); // Clear dropdown
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {city?.cityname}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )}
                    <div
                        className="absolute md:h-[50px] max-md:h-[40px] md:min-w-[50px] max-md:min-w-[40px] py-[8px] right-0 top-0 flex items-center justify-center text-white bg-[#f38432] cursor-pointer"
                        onClick={handleSearch}
                    >
                        <IoSearch className="md:text-[18px] max-md:text-[16px]" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Search;
