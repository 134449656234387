// import { Google } from "@mui/icons-material";
import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const SEO = ({ title = "", description = "", keywords = "", schema = "", business = "", organization = "",
  faq = "", blog1 = "", blog2 = "", blog3 = "", blog4 = "", GoogleManager = "", GoogleManager1 = "", noIndex = false }) => {
  const { pathname } = useLocation();

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title || "My Internet "}</title>
      <meta
        name="description"
        content={description || "Find the best internet service providers offering fast, high-speed fiber and broadband connections. Explore affordable packages and providers near you."}
      />
      <meta property="og:title" content={title || "My Internet "} />
      <meta property="og:description" content={description} />
      {noIndex && (
        <meta name="robots" content="noindex, nofollow" />
      )}

      <link rel="canonical" href={`https://www.myinternet.services${pathname}`} />
      {keywords && <meta name="keywords" content={keywords} />}
      
      {GoogleManager && (
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11440874955">
          {GoogleManager}
        </script>
      )}
      {GoogleManager1 && (
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11440874955">
          {GoogleManager1}
        </script>
      )}
      {schema && (
        <script defer type="application/ld+json">
          {schema}
        </script>
      )}
      {business && (
        <script defer type="application/ld+json">
          {business}
        </script>
      )}
      {organization && (
        <script defer type="application/ld+json">
          {organization}
        </script>
      )}
      {faq && (
        <script defer type="application/ld+json">
          {faq}
        </script>
      )}
      {blog1 && (
        <script defer type="application/ld+json">
          {blog1}
        </script>
      )}
      {blog2 && (
        <script defer type="application/ld+json">
          {blog2}
        </script>
      )}
      {blog3 && (
        <script defer type="application/ld+json">
          {blog3}
        </script>
      )}
      {blog4 && (
        <script defer type="application/ld+json">
          {blog4}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
