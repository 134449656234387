import * as React from "react"
import ImageIcons from "../components/imageComponent/ImageIcons"
import Layout from "../components/shared/layout"
import Button from "../components/shared/button"
import {useNavigate} from "react-router-dom";
// import { Link } from "react-router-dom"

const NotFoundPage = () => {
  const navigate = useNavigate()

  return (
    <>
      <Layout>
        <section>
          <div className="container ">
            <div className="text-center">
              <h1 className="flex items-center justify-center sm:text-[230px] text-[110px] text-[#fff] bg-[#141416] mx-auto  sm:w-[630px] w-full sm:h-[350px] h-[200px] rounded-[10px] mb-[35px]">4 <img loading="lazy" className="sm:max-w-[230px] max-w-[110px]" src={ImageIcons.ghostWhite} alt="ghodtwhite" /> 4</h1>
              <h2 className="text-[#141416] mb-[30px]">Oops! Something is wrong.</h2>
              <Button  onClick={() => { navigate("/");window.location.pathname = "/"}}title='Return to Home' />
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default NotFoundPage