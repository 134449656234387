import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuDrawer from "./drawer";
import { FaFacebookF, FaPinterest, FaUser } from "react-icons/fa";
import { FaXTwitter, FaInstagram, FaLinkedin, FaLocationDot } from "react-icons/fa6";
import { IoLogoYoutube, IoMdMail, IoMdCopy } from "react-icons/io";
import { windowScroll } from "../utils/windowScroll";
import { MdCall, MdSearchOff } from "react-icons/md";
import { FiLogIn } from "react-icons/fi";
import { IoLogoGooglePlaystore, IoCheckmarkDoneSharp, IoSearch } from "react-icons/io5";
import ImageIcons from '../imageComponent/ImageIcons';
import axios from 'axios';
import servicesData from '../utils/data/service.json'
import { UIContext } from '../../utils/context';
import { toast } from 'react-toastify';
import ScrollToTopButton from "../utils/bottomToTopScroll";
import { MdContactPage } from "react-icons/md";
import { GrClose } from "react-icons/gr";


const Layout = ({ children, title = "" }) => {
  const navigate = useNavigate()
  const [animationSearch, setAnimationSearch] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const [, setShowLogoutModal] = useState(false);
  const currentYear = new Date().getFullYear();

  const pathname = useLocation().pathname;

  const storedUser = JSON.parse(localStorage.getItem('user'));
  const id = storedUser?._id

  const handle = () => {
    setShowLogoutModal(true);
  }
  // Header On Scroll
  const handleScroll = () => {
    const offset = window.scrollY;
    setSticky(offset > 300);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [searchTerm, setSearchKey] = useState("");
  const { setSearchTerm, setSearchedData } = useContext(UIContext);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [blogData, setBlogData] = useState([]);

  const fetchBlogs = async () => {
    const response = await axios.get(`${process.env.REACT_APP_URL}user/getblog`);
    setBlogData(response.data.data)
  }

  useEffect(() => {
    fetchBlogs()
  }, [])

  useEffect(() => {
    const filteredResult =
      Array.isArray(blogData) && blogData.length > 0
        ? blogData.filter((blogItem) =>
          blogItem.isActive === true &&
          (blogItem.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            blogItem.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            blogItem.author?.toLowerCase().includes(searchTerm.toLowerCase()))
        )
        : [];
    setFilteredBlogs(filteredResult);
  }, [searchTerm, blogData]);


  useEffect(() => {
    const filteredServiceResult =
      Array.isArray(servicesData) && servicesData.length > 0
        ? servicesData.filter((serviceItem) => {
          const searchTermLower = searchTerm.toLowerCase();
          const additionalInfoMatches = serviceItem?.additionalInfo?.some(
            (info) =>
              info.title?.toLowerCase().includes(searchTermLower) ||
              info.description?.toLowerCase().includes(searchTermLower)
          );
          const slowFeaturesMatches = serviceItem?.features?.some(
            (feature) =>
              feature.title?.toLowerCase().includes(searchTermLower) ||
              feature.description?.toLowerCase().includes(searchTermLower)
          );
          const fastFeaturesMatches = serviceItem?.plans?.some(
            (feature) =>
              feature?.title?.toLowerCase().includes(searchTermLower) ||
              feature?.description?.toLowerCase().includes(searchTermLower)
          );
          return (
            serviceItem?.heading?.toLowerCase().includes(searchTermLower) ||
            serviceItem?.type?.toLowerCase().includes(searchTermLower) ||
            serviceItem?.title?.toLowerCase().includes(searchTermLower) ||
            serviceItem?.description?.toLowerCase().includes(searchTermLower) ||
            serviceItem?.headingdescription?.toLowerCase().includes(searchTermLower) ||
            additionalInfoMatches ||
            slowFeaturesMatches ||
            fastFeaturesMatches
          );
        })
        : [];
    setFilteredServices(filteredServiceResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, servicesData]);


  const searchDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchDropdownRef.current && !searchDropdownRef.current.contains(event.target)) {
        setSearchKey('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchDropdownRef]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSearchedData({ blogs: filteredBlogs, services: filteredServices });
      setSearchTerm(searchTerm);
      setFilteredServices([]);
      console.log("Searched term:", searchTerm);
      setSearchKey("");
      navigate("/search", {
        state: {
          searchTerm: searchTerm
        }
      });
    }
  };

  const handleSearchSubmit = () => {
    if (!searchTerm.trim()) {
      // Do not perform search if searchTerm is empty
      return;
    }
    setSearchedData({ blogs: filteredBlogs, services: filteredServices });
    setSearchTerm(searchTerm);
    setFilteredServices([]);
    console.log("Searched term:", searchTerm);
    setSearchKey("");
    navigate("/search", {
      state: {
        searchTerm: searchTerm
      }
    });
  };
  function resetHandler() {
    setSearchKey("");
    setFilteredServices([]);
  }

  const handleCopyText = () => {
    const tempInput = document.createElement('input');
    document.body.appendChild(tempInput);
    tempInput.value = (data.mail);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    toast.success('Copied to Clipboard');
  };

  const searchOpne = () => {
    setAnimationSearch(!animationSearch);
  }

  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', animationSearch);

    return () => {
      document.body.classList.remove('overflow-visible');
    };
  }, [animationSearch]);



  return (
    <>
      <header className="relative">
        <div className="bg-black 2xl:py-[20px] py-[10px] relative">
          <div className="container">
            <div className="flex max-md:flex-col justify-between items-center gap-[15px]">
              <div>
                <ul className="flex lg:gap-[60px] md:gap-[30px] gap-[20px]">
                  <li>
                    <Link to="/contact-us" className="text-white hover:text-[#f68140] 2xl:text-[24px] xl:text-[22px] md:text-[20px] sm:text-[18px] text-[16px] font-bold cursor-pointer flex items-center sm:gap-[10px] gap-[6px]">
                      <MdContactPage className="inline-block 2xl:text-[35px] xl:text-[32px] md:text-[30px] sm:text-[25px] text-[23px]" />
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link className="text-white hover:text-[#f68140] 2xl:text-[24px] xl:text-[22px] md:text-[20px] sm:text-[18px] text-[16px] font-bold" to={`tel:${data.mobileNo}`}><MdCall className="inline-block 2xl:text-[35px] xl:text-[32px] md:text-[30px] sm:text-[25px] text-[23px] sm:mr-[10px] mr-[6px]" />{data.mobileNo}</Link>
                  </li>
                </ul>
              </div>

              {animationSearch ? (
                <div className="fixed w-full h-screen z-50 bg-black flex items-center justify-center left-0 top-0">
                  <button type="button" className="text-white hover:text-[#fd5901] duration-500 absolute md:top-[10%] top-[calc(50%_-_75px)] md:right-[10%] right-[5%]" onClick={searchOpne}>
                    <GrClose className="xl:text-[35px] sm:text-[28px] text-[25px]" /></button>
                  <div className="relative rounded-[3px] md:w-[400px] w-[90%]" ref={searchDropdownRef}>
                    <input
                      value={searchTerm}
                      type="search"
                      placeholder="Search...."
                      onChange={(e) => setSearchKey(e.target.value)}
                      onKeyDown={handleKeyPress}
                      className="h-[55px] pl-[15px] pr-[50px] focus:outline-none w-full rounded-[2px]" />

                    {searchTerm && (
                      <div className='absolute top-full mt-[1px] left-0 w-full rounded shadow-[0_5px_10px_5px_rgba(0,0,0,0.2)] bg-white z-[999999]'>
                        {filteredBlogs.length === 0 && filteredServices.length === 0 ? (
                          <ul>
                            <li>
                              <div className='text-[#fd5901] p-[10px] flex items-center'>
                                <MdSearchOff className='h-5 w-6 cursor-pointer inline-block' />
                                Not Found
                              </div>
                            </li>
                          </ul>
                        ) : (
                          <ul>
                            {filteredBlogs?.map((blogItem) => (
                              <li key={blogItem?._id} className="text-white border-t first:border-hidden">
                                <Link
                                  onClick={resetHandler}
                                  className='py-[10px] px-[15px] inline-block w-full text-[#000]'
                                  to={`/blogs/${blogItem?.handle?.replace(/\s+/g, "-").toLowerCase()}`}
                                >
                                  {blogItem?.title.slice(0, 30)}.....
                                </Link>
                              </li>
                            ))}
                            {filteredServices?.map((serviceItem) => (
                              <li key={serviceItem?._id} className="text-white border-t first:border-hidden">
                                <Link
                                  onClick={resetHandler}
                                  className='py-[10px] px-[15px] inline-block w-full text-[#000]'
                                  to={`/service/${serviceItem?.type?.replace(/\s+/g, "-").toLowerCase()}`}
                                >
                                  {serviceItem?.title.slice(0, 30)}...
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    )}
                    <button type="button" className="h-[55px] w-[55px] duration-500 bg-[#f68140] hover:bg-[#fd5901] text-white absolute top-0 right-0 flex justify-center items-center rounded-r-[2px]">
                      <IoSearch className="text-[22px]" onClick={handleSearchSubmit} /></button>
                  </div>
                </div>) : null}

              <div className="flex lg:gap-[45px] md:gap-[35px] gap-[20px] items-center max-md:hidden">
                <button type="button" className="text-white hover:text-[#f68140] duration-500" onClick={searchOpne}>
                  <IoSearch className="xl:text-[25px] sm:text-[22px] text-[20px]" /></button>

                <div className="flex md:gap-[15px] gap-[12px]">
                  <Link to='https://www.facebook.com/myinternetMohali/' target="_blank" className='text-white xl:text-[24px] md:text-[22px] sm:text-[20px] text-[18px] hover:text-[#f68140]'><FaFacebookF /></Link>
                  <Link to='https://www.instagram.com/myinternet__/' target="_blank" className='text-white xl:text-[24px] md:text-[22px] sm:text-[20px] text-[18px] hover:text-[#f68140]'><FaInstagram /> </Link>
                  <Link to='https://twitter.com/MyInternet_' target="_blank" className='text-white xl:text-[24px] md:text-[22px] sm:text-[20px] text-[18px] hover:text-[#f68140]'><FaXTwitter /> </Link>
                  <Link to='https://www.youtube.com/@MyInternet_' target="_blank" className='text-white xl:text-[24px] md:text-[22px] sm:text-[20px] text-[18px] hover:text-[#f68140]'><IoLogoYoutube /></Link>
                  <Link to='https://www.linkedin.com/company/my-internet/' target="_blank" className='text-white xl:text-[24px] md:text-[22px] sm:text-[20px] text-[18px] hover:text-[#f68140]'><FaLinkedin /></Link>
                  <Link to='https://www.pinterest.com/myinternet_/' target="_blank" className='text-white xl:text-[24px] md:text-[22px] sm:text-[20px] text-[18px] hover:text-[#f68140]'><FaPinterest /> </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={` bg-[#fff] 2xl:py-[25px] lg:py-[15px] py-[10px] z-40 ${isSticky ? 'header-position' : ''}`} >
          <div className="container">
            <div className="flex gap-[5px] justify-between items-center lg:h-auto">
              <Link to="/" className="flex items-center mr-[10px]"
                onClick={() => { window.location.pathname = "/" }}
              >
                <img
                  src={ImageIcons.logo}
                  className="2xl:max-h-[110px] xl:max-h-[90px] lg:max-h-[80px] max-h-[60px]"
                  alt='Logo'
                  
                />
              </Link>

              <nav className='lg:flex hidden'>
                <ul className="flex items-center lg:flex-nowrap flex-wrap lg:justify-center justify-start justify-left">
                  {data?.navLinks.map((link, i) => {
                    const isSubLinkActive = link?.subLinks?.some(subLink =>
                      pathname.includes(subLink.handle) || pathname.includes(subLink.type)
                    );
                    return (
                      <li key={i} className="relative 2xl:min-w-[140px] xl:min-w-[120px] min-w-[90px] max-xl:px-[8px] text-center group lg:w-auto w-full whitespace-nowrap 2xl:py-[13px] py-[10px] after:absolute after:content-[''] after:top-[25px] after:left-0 after:w-[1px] after:h-[22px] after:bg-[#D9D9D9] first:after:hidden">
                        {!link?.handle ? (
                          <div className={`text-[#000] cursor-pointer hover:text-[#f48533] group-hover:text-[#f48533] 2xl:text-[18px] xl:text-[17px] lg:text-[15px] lg:inline-block block w-full duration-500 ${(pathname === link.handle || isSubLinkActive) && "!text-[#f48533]"}`}>
                            <img src={link.icon} alt="Menu" className={` mx-auto 2xl:mb-[18px] xl:mb-[12px] mb-[10px] 2xl:max-h-[40px] xl:max-h-[30px] max-h-[25px] duration-500 brightness-0 group-hover:filter-none ${(pathname === link.handle || isSubLinkActive) && "filter-none"}`} />
                            {link.title}
                          </div>
                        ) : (
                          <Link to={link.handle} className={`text-[#000] hover:text-[#f48533] group-hover:text-[#f48533] 2xl:text-[18px] xl:text-[17px] lg:text-[15px] lg:inline-block block w-full ${(pathname === link.handle || isSubLinkActive) && "!text-[#f48533]"}`}>
                            <img src={link.icon} alt="Menu" className={` mx-auto 2xl:mb-[18px] xl:mb-[12px] mb-[10px] 2xl:max-h-[40px] xl:max-h-[30px] max-h-[25px] duration-500 brightness-0 group-hover:filter-none ${(pathname === link.handle || isSubLinkActive) && "filter-none"}`} />
                            {link.title}
                          </Link>
                        )}

                        {link?.subLinks && (
                          <ul className="duration-500 left-0 shadow-[0px_0px_15px_0px_rgba(0,0,0,0.2)] group-hover:visible group-hover:h-auto group-hover:overflow-visible grid grid-cols-2 gap-x-[20px] bg-white text-black md:absolute relative top-[100%] md:w-[600px] w-[100%] z-[1111] invisible overflow-hidden h-0">
                            {link?.subLinks?.map((item, i) => (
                              <li key={i} className="w-fill">
                                {item?.type &&
                                  <>
                                    {(item.title === "IPTV") ?
                                      (
                                        <Link to="https://www.skypro.co.in/iptv-customer-support" className={`border-b group/icon text-left w-full flex items-center border-[#3c62e921] text-[16px] hover:text-[#f48533] px-[20px] py-[15px] ${pathname === `/service/${item?.type}` && " text-[#f48533]"}`}
                                        >
                                          <img loading="lazy" src={item.icon} alt="Menu" className={` max-w-[31px] object-contain brightness-0 group-hover/icon:filter-none mr-[10px] duration-500 ${pathname === `/service/${item?.type}` && "filter-none"}`} />
                                          {item?.title}
                                        </Link>
                                      )
                                      : (
                                        <Link to={`/service/${item?.type}`} className={`border-b group/icon text-left w-full flex items-center border-[#3c62e921] text-[16px] hover:text-[#f48533] px-[20px] py-[15px] ${pathname === `/service/${item?.type}` && " text-[#f48533]"}`}
                                          onClick={() => { window.location.pathname = `/service/${item?.type}` }}
                                        >
                                          <img loading="lazy" src={item.icon} alt="Menu" className={` max-w-[31px] object-contain brightness-0 group-hover/icon:filter-none mr-[10px] duration-500 ${pathname === `/service/${item?.type}` && "filter-none"}`} />
                                          {item?.title}
                                        </Link>
                                      )}
                                  </>
                                }

                                {item?.handle &&
                                  <Link to={`/pricing/${item?.handle}`} className={`border-b group/icon text-left w-full flex items-center border-[#3c62e921] text-[16px] hover:text-[#f48533] px-[20px] py-[15px]  ${pathname === `/pricing/${item?.handle}` && " text-[#f48533]"}`}
                                    onClick={() => { window.location.pathname = `/pricing/${item?.handle}` }}
                                  >
                                    <img  src={item.icon} alt="Menu" className={` max-w-[31px] object-contain brightness-0 group-hover/icon:filter-none mr-[10px] duration-500 ${pathname === `/pricing/${item?.handle}` && "filter-none"}`} />
                                    {item?.title}
                                  </Link>
                                }
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    );
                  })}
                </ul>
                <ul className="flex items-center border border-[#f48533] rounded-[17px] ml-[10px]">
                  {/* <li className="relative group lg:w-auto w-full whitespace-nowrap 2xl:py-[13px] py-[10px] 2xl:px-[25px] xl:px-[20px] px-[15px] after:absolute after:-translate-y-2/4 after:content-[''] after:top-[50%] after:right-0 after:w-[1px] 2xl:after:h-[50px] after:h-[40px] after:bg-[#f48533]">
                  <Link to='#' className={`text-[#000] font-medium hover:text-[#f48533] 2xl:text-[18px] xl:text-[17px] lg:text-[15px] lg:inline-block block w-full`}
                  ><img src={ImageIcons.quickPayMenu} alt="Menu" className="mx-auto 2xl:mb-[18px] xl:mb-[12px] mb-[10px] 2xl:max-h-[40px] xl:max-h-[30px] max-h-[25px] duration-500 brightness-0 group-hover:filter-none" />Quick Pay</Link>
                </li> */}
                  <li className="relative group lg:w-auto w-full whitespace-nowrap 2xl:py-[13px] py-[10px] 2xl:px-[25px] xl:px-[20px] px-[15px]">
                    <Link to='https://myaccount.myinternetzone.com/' target="_blank" className={`text-[#000] font-medium hover:text-[#f48533] 2xl:text-[18px] xl:text-[17px] lg:text-[15px] lg:inline-block block w-full`}
                    ><img src={ImageIcons.myAccountMenu} alt="Menu" className="mx-auto 2xl:mb-[18px] xl:mb-[12px] mb-[10px] 2xl:max-h-[40px] xl:max-h-[30px] max-h-[25px] duration-500 brightness-0 group-hover:filter-none" />My Account</Link>

                    {/* <ul className="duration-500 left-0 shadow-[0px_0px_15px_0px_rgba(0,0,0,0.2)] group-hover:visible group-hover:h-auto group-hover:overflow-visible grid bg-white text-black md:absolute relative top-[100%] md:w-[190px] w-[100%] z-[1111] invisible overflow-hidden h-0">
                      <li className="w-fill">
                        <Link to='/get-myinternet' onClick={windowScroll} className='border-b w-full inline-block	border-[#3c62e921] text-[16px] hover:bg-[#f48533] hover:text-white px-[20px] py-[10px]'>Get New Connection</Link>
                      </li>
                      <li className="w-fill">
                        <Link to='/contact-us' onClick={windowScroll} className='border-b w-full inline-block	border-[#3c62e921] text-[16px] hover:bg-[#f48533] hover:text-white px-[20px] py-[10px]'>Pay Bill / Recharge</Link>
                      </li>
                      {!id ?
                        <>
                          <li className="w-fill">
                            <Link to='/register' className='border-b w-full inline-block	border-[#3c62e921] text-[16px] hover:bg-[#f48533] hover:text-white px-[20px] py-[10px]'>Register</Link>
                          </li> 

                          <li className="w-fill">
                            <Link to='https://myaccount.myinternetzone.com/' target="_blank" className='border-b w-full inline-block	border-[#3c62e921] text-[16px] hover:bg-[#f48533] hover:text-white px-[20px] py-[10px]'>Login</Link>
                          </li>
                        </>
                        :
                        null
                      }

                      {id ? (
                        <>
                          <li className="w-fill">
                            <Link to='/customer' className='border-b w-full inline-block	border-[#3c62e921] text-[16px] hover:bg-[#f48533] hover:text-white px-[20px] py-[10px]'>Customer Portal</Link>
                          </li>
                          <li>
                            <button type="button" onClick={() => handle()} className='border-b w-full inline-block text-left border-[#3c62e921] text-[16px] hover:bg-[#f48533] hover:text-white px-[20px] py-[10px]'>Logout</button>
                          </li>
                        </>
                      ) : null}

                    </ul> */}
                  </li>
                </ul>
              </nav>

              <div className="flex gap-[20px] lg:hidden">
                <button type="button" className="text-black hover:text-[#f68140] duration-500 md:hidden" onClick={searchOpne}>
                  <IoSearch className="text-[24px]" /></button>
                <MenuDrawer navLinks={data.navLinks} />
              </div>
            </div>
          </div>
        </div>
      </header >
      {children}
      < footer className="bg-[#141416] bg-center bg-contain bg-no-repeat max-md:mb-[70px]" style={{ backgroundImage: `url(${ImageIcons.footerBg})` }
      }>
        <div className="container" >
          <div className="flex max-xl:flex-wrap gap-[40px] md:py-[50px] py-[30px]">
            <div className="xl:w-[25%] w-full">
              <Link className="inline-block mb-[15px]" to='/'><img onClick={windowScroll} className="w-[150px]" src={ImageIcons.logo} alt="Logo"></img></Link>
              <p className="text-[#D7D7D7] mb-[10px]">We, at My Internet are focused towards offering our users with high-speed, reliable and affordable internet services via a wide range of plans customised to your needs. With us, you will enjoy seamless streaming, uploading, downloading and much more.</p>
              <div className="mt-[10px]">
                <Link to='https://cloud.jazenetworks.com/signin' target="_blank" className='text-[16px] xl:w-[290px] sm:w-[190px] w-full text-[#fff] hover:text-[#fff] rounded-[10px] border border-[#D7D7D7] hover:border-[#F48533] hover:bg-[#F48533] mt-[10px] p-[12px] inline-flex justify-center items-center sm:mr-[10px]'>Franchisee Login <FiLogIn className="ml-[10px]" /></Link>
                <Link to='https://myaccount.myinternetzone.com/' target="_blank" className='text-[16px] sm:w-[140px] w-full text-[#fff] hover:text-[#fff] rounded-[10px] border border-[#D7D7D7] hover:border-[#F48533] hover:bg-[#F48533] mt-[10px] p-[12px] inline-flex justify-center items-center'>User Login <FaUser className="ml-[10px]" /></Link>
                <Link to='https://play.google.com/store/apps/details?id=app.customerportal.digitax' target="_blank" className='text-[16px] sm:w-[140px] w-full text-[#fff] hover:text-[#fff] rounded-[10px] border border-[#D7D7D7] hover:border-[#F48533] hover:bg-[#F48533] mt-[10px] p-[12px] inline-flex justify-center items-center sm:ml-[10px]'>User App <IoLogoGooglePlaystore className="ml-[10px]" /></Link>
              </div>
            </div>

            <div className="xl:w-[75%] w-full grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 md:gap-[20px] gap-[30px]">
              {footerLinks.map((link, i) => (
                <div className="" key={i}>
                  <h3 className="text-[#F48533] font-medium	pb-[8px] sm:mb-[25px] text-[20px] md:text-[24px] relative before:content before:left-[0] before:bottom-[0] before:absolute before:h-[1.5px] before:w-[80px] before:bg-gradient-to-r before:from-[#F48533]" >{link.title}</h3>
                  <ul className="grid gap-[10px] sm:gap-[12px] md:gap-[15px]">
                    {link.subLinks.map((item, i) => (
                      <li key={i}>
                        {item.handle1 &&
                          <Link to={item.handle1} className={`text-[16px] text-[#cecece] hover:text-[#F48533] flex items-center ${pathname === item.handle1 && "text-[#f48533]"} `} onClick={() => {
                            window.location.pathname = item.handle1;
                            windowScroll();
                          }} >
                            <IoCheckmarkDoneSharp className="mr-[6px] text-[#F48533]" /> {item.link}
                          </Link>
                        }

                        {item?.type &&
                          <>
                            {(item?.link === "IPTV") ?
                              (
                                <Link to="https://www.skypro.co.in/iptv-customer-support"
                                  className={`text-[16px] text-[#cecece] hover:text-[#F48533] flex items-center ${pathname === `/service/${item?.type}` && "text-[#f48533]"} `} >
                                  <IoCheckmarkDoneSharp className="mr-[6px] text-[#F48533]" /> {item.link}
                                </Link>
                              )
                              :

                              ((item?.link === "Cloud Cam") ? (
                                <Link to="https://www.mycloudcam.in/"
                                  className={`text-[16px] text-[#cecece] hover:text-[#F48533] flex items-center ${pathname === `/service/${item?.type}` && "text-[#f48533]"} `} >
                                  <IoCheckmarkDoneSharp className="mr-[6px] text-[#F48533]" /> {item.link}
                                </Link>
                              ) : (
                                <Link to={`/service/${item?.type}`} className={`text-[16px] text-[#cecece] hover:text-[#F48533] flex items-center ${pathname === `/service/${item?.type}` && "text-[#f48533]"} `} onClick={() => {
                                  window.location.pathname = `service/${item?.type}`;
                                  windowScroll();
                                }} >
                                  <IoCheckmarkDoneSharp className="mr-[6px] text-[#F48533]" /> {item.link}
                                </Link>)
                              )
                            }
                          </>
                        }

                        {item?.handle &&
                          <Link to={`/pricing/${item?.handle}`} className={`text-[16px] text-[#cecece] hover:text-[#F48533] flex items-center ${pathname === `/pricing/${item?.handle}` && "text-[#f48533]"} `} onClick={() => {
                            window.location.pathname = `pricing/${item?.handle}`;
                            windowScroll();
                          }}>
                            <IoCheckmarkDoneSharp className="mr-[6px] text-[#F48533]" /> {item.link}
                          </Link>
                        }
                      </li>
                    ))}
                  </ul>
                </div>
              ))}

              <div className="my-[10px] lg:my-[0]">
                <Link to="/contact-us" onClick={windowScroll} className="px-[50px] text-[18px] py-[13px] text-[#fff] bg-[#F68140] rounded-[10px] hover:bg-[#f7752c] ease inline-block mb-[25px]">Contact Us</Link>
                <div className="grid gap-[15px] md:gap-[20px]">
                  <Link to={`tel:${data.mobileNo}`} className="flex gap-[10px] items-center text-[16px] text-[#D7D7D7] hover:text-[#F48533] duration-500"><MdCall className="text-[30px] min-w-[30px]" />{data.mobileNo}</Link>

                  <div onClick={handleCopyText} className="flex gap-[10px] items-center text-[16px] text-[#D7D7D7] hover:text-[#F48533] duration-500 cursor-pointer"><IoMdMail className="text-[30px] min-w-[30px]" />Email Us <IoMdCopy className="inline-block text-[19px]" /></div>

                  <div className="flex gap-[10px] items-start">
                    <FaLocationDot className="text-[#cecece] text-[30px] min-w-[30px]" />
                    <span className="text-[16px] text-[#D7D7D7]">{data.address}</span>
                  </div>
                  <div className="flex gap-[10px] items-start">
                    <FaLocationDot className="text-[#cecece] text-[30px] min-w-[30px]" />
                    <span className="text-[16px] text-[#D7D7D7]">{data.address1}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
        <div className="bg-[#000]">
          <div className="container">
            <div className="flex md:justify-between justify-center items-center md:flex-nowrap flex-wrap py-[20px] gap-[10px]">
              <p className="text-[16px] text-[#cecece] mb-0 md:text-left text-center">© Copyright {currentYear} MyInternet. All Right Reserved</p>
              <div className="md:text-left text-center md:w-auto w-full">
                <Link to='/privacy-policy' className={`text-[#cecece] hover:text-[#F48533] lg:mx-[20px] mx-[10px] my-[px] inline-block  ${pathname === `/privacy-policy` && "text-[#f48533]"} `} onClick={() => {
                  window.location.pathname = '/privacy-policy';
                  windowScroll()
                }}>Privacy Policy</Link>
                <Link className={`text-[#cecece] hover:text-[#F48533] lg:mx-[20px] mx-[10px] my-[8px] inline-block  ${pathname === `/terms-conditions` && "text-[#f48533]"} `} onClick={() => {
                  window.location.pathname = '/terms-conditions';
                  windowScroll()
                }} to='/terms-conditions'>Terms & Conditions</Link>
                <Link className={`text-[#cecece] hover:text-[#F48533] lg:mx-[20px] mx-[10px] my-[8px] inline-block  ${pathname === `/refund-policy` && "text-[#f48533]"} `} onClick={() => {
                  window.location.pathname = '/refund-policy';
                  windowScroll()
                }} to='/refund-policy'>Refund Policy</Link>
                <Link className={`text-[#cecece] hover:text-[#F48533] lg:mx-[20px] mx-[10px] my-[8px] inline-block  ${pathname === `/disclaimer` && "text-[#f48533]"} `} onClick={() => {
                  window.location.pathname = '/disclaimer';
                  windowScroll()
                }} to='/disclaimer'>Disclaimer</Link>
              </div>
            </div>
          </div>
        </div>
      </footer >
      < ScrollToTopButton />
      <div className="fixed top-[50%] right-0 z-[40] -translate-y-2/4 max-md:hidden" >
        <Link to='/get-myinternet'
          className='flex-col 2xl:text-[14px] text-[11px] rounded-tl-[5px] text-center 2xl:leading-[15px] leading-[13px] 2xl:py-[16px] lg:py-[12px] py-[10px] 2xl:mb-[4px] mb-[2px] border-transparent text-[#fff] bg-[#F68140] flex justify-center 2xl:gap-[12px] gap-[6px] items-center relative group 2xl:w-[110px] w-[70px] ml-auto 2xl:hover:w-[120px] hover:w-[75px]'
          onClick={() => {
            window.location.pathname = '/get-myinternet';
            windowScroll()
          }}>
          <img loading="lazy" src={ImageIcons.newConnection} alt="New Connection" className="2xl:h-[30px] lg:h-[28px] h-[25px]" />
          New<br></br> Connection
        </Link>
        {/* <Link to='' className='flex-col 2xl:text-[14px] text-[11px] text-center 2xl:leading-[15px] leading-[13px] 2xl:py-[16px] py-[8px] shadow-[0px_1px_5px_rgba(0,0,0,0.15)] 2xl:mb-[4px] mb-[2px] border-transparent text-[#fff] bg-[#F68140] flex justify-center 2xl:gap-[12px] gap-[5px] items-center relative group 2xl:w-[110px] w-[70px] ml-auto 2xl:hover:w-[120px] hover:w-[75px]'>
          <img src={ImageIcons.quickPay} alt="Quick Pay" className="2xl:h-[30px] lg:h-[27px] h-[24px]" />
          Quick Pay
        </Link>
        <Link to='' className='flex-col 2xl:text-[14px] text-[11px] rounded-bl-[5px] text-center 2xl:leading-[15px] leading-[13px] 2xl:py-[16px] py-[8px] shadow-[0px_1px_5px_rgba(0,0,0,0.15)] border-transparent text-[#fff] bg-[#F68140] flex justify-center 2xl:gap-[12px] gap-[5px] items-center relative group 2xl:w-[110px] w-[70px] ml-auto 2xl:hover:w-[120px] hover:w-[75px]'>
          <img src={ImageIcons.checkFeasibility} alt="Check Feasibility" className="2xl:h-[30px] lg:h-[27px] h-[24px]" />
          Check<br></br> Feasibility
        </Link> */}
      </div>

      <div className="md:hidden fixed left-0 bottom-0 w-full z-40 bg-[#F68140] shadow-[0px_0px_15px_rgba(0,0,0,0.50)] text-white py-[10px]">
        <div className="container">
          <ul className="flex justify-between items-center">
            <li>
              <Link to='' className="text-[14px]">
                <img src={ImageIcons.quickPay} alt="Quick Pay" className="mx-auto max-h-[25px] mb-[2px]" />
                Quick Pay
              </Link>
            </li>
            <li>
              <Link to='/pricing' onClick={windowScroll} className="text-[14px]">
                <img src={ImageIcons.viewPlansButtomMenu} alt="Quick Pay" className="mx-auto max-h-[25px] mb-[2px]" />
                View Plans
              </Link>
            </li>
            <li className="w-[68px] h-[68px] bg-[#000] flex justify-center items-center leading-4 ml-[-30px] mt-[-77px] mr-[-30px] rounded-full shadow-[0px_-2px_0px_2px_rgba(255,255,255,0.20)]">
              <Link to='/get-myinternet' onClick={windowScroll} className="text-[14px]">
                Book <br />Now
              </Link>
            </li>
            <li>
              <Link to='' className="text-[14px]">
                <img  src={ImageIcons.checkFeasibility} alt="Quick Pay" className="mx-auto max-h-[25px] mb-[2px]" />
                Feasibility
              </Link>
            </li>
            <li>
              <Link to='https://myaccount.myinternetzone.com/customer_portal' target="_blank" className="text-[14px]">
                <img src={ImageIcons.myAccountsButtomMenu} alt="Quick Pay" className="mx-auto max-h-[25px] mb-[2px]" />
                My accounts
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* <ToastContainer position="bottom-right" autoClose={1000} hideProgressBar={true} /> */}
    </>
  );
};

const data = {
  mobileNo: "+91 9464001234",
  mail: "info@myinternet.services",
  address: "Plot No. 205, Near Markanda Complex, Opp. Punjabi University, Patiala | Punjab 147002",
  address1: "B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055",
  navLinks: [
    {
      title: "New Connection",
      handle: "/get-myinternet",
      icon: ImageIcons.newConnectionMenu,
    },

    {
      title: "Tariff Plans",
      handle: "/pricing",
      icon: ImageIcons.tariffPlansMenu,
      subLinks: [
        {
          title: "Home Internet",
          handle: "home-internet",
          icon: ImageIcons.homeWifiSubMenu,
        },
        {
          title: "Business Internet",
          handle: "business-internet",
          icon: ImageIcons.businessInternetSubMenu,
        },
        {
          title: "Custom Plan",
          handle: "custom-plan",
          icon: ImageIcons.customPlanSubMenu,
        },
        {
          title: "OTT",
          handle: "ott",
          icon: ImageIcons.ottPlanSubMenu,
        },
        {
          title: "Entertainment Combo",
          handle: "entertainment-combo",
          icon: ImageIcons.entertainmentComboPlanSubMenu,
        },
      ]
    },
    {
      title: "Our Services",
      // handle: "/services-details",
      icon: ImageIcons.ourServicesMenu,
      subLinks: [
        {
          title: "Internet Leased Line",
          type: "internet-leased-line",
          icon: ImageIcons.leasedLineSubMenu,
        },
        {
          title: "Home Internet Provider",
          type: "home-internet-provider",
          icon: ImageIcons.homeWifiSubMenu,
        },
        {
          title: "Business Internet Provider",
          type: "business-internet-provider",
          icon: ImageIcons.businessInternetSubMenu,
        },
        {
          title: "IPTV",
          type: "https://www.skypro.co.in/iptv-customer-support",
          icon: ImageIcons.iptvSubMenu,
        },
        {
          title: "Free Wifi Hotspot",
          type: "free-wifi-hotspot",
          icon: ImageIcons.hotspotSubMenu,
        },
        // {
        //   title: "Managed Network",
        //   type: "managed-network",
        //   icon: ImageIcons.managedNetworkSubMenu,
        // },
        // {
        //   title: "Cloud Cam",
        //   type: "cloud-cam",
        //   icon: ImageIcons.cloudCamSubMenu,
        // },
        // {
        //   title: "VoIP Intercom",
        //   type: "voip-intercom",
        //   icon: ImageIcons.voipIntercomSubMenu,
        // },
        // {
        //   title: "CDN Services",
        //   type: "cdn-services",
        //   icon: ImageIcons.cdnSubMenu,
        // },

      ]
    },
    {
      title: "Partner with us",
      handle: "/franchisee",
      icon: ImageIcons.partnerWithUsMenu,
    },
    {
      title: "Blogs",
      handle: "/blogs",
      icon: ImageIcons.blogsMenu,
    },
    {
      title: "About Us",
      handle: "/about-us",
      icon: ImageIcons.aboutUsMenu,
    },
  ]
}

const footerLinks = [
  {
    title: "Useful Links",
    subLinks: [
      {
        link: "Vision",
        handle1: "/vision"
      },
      {
        link: "Blogs",
        handle1: "/blogs"
      },
      {
        link: "Career",
        handle1: "/career"
      },
      {
        link: "About Us",
        handle1: "/about-us"
      },
      {
        link: "FAQs",
        handle1: "/faqs"
      },
      {
        link: "States",
        handle1: "/states"
      },
      // {
      //   link: "Get MyInternet",
      //   handle1: "/get-myinternet"
      // },
      // {
      //   link: "Get Leased Line",
      //   handle1: "/get-leasedline"
      // },
      {
        link: "Sitemap",
        handle1: "/sitemap"
      },
    ]
  },
  {
    title: "Services",
    subLinks: [
      {
        link: "Internet Leased Line",
        type: "internet-leased-line",
        icon: ImageIcons.partnerWithUsMenu,
      },
      {
        link: "Business Internet Provider",
        type: "business-internet-provider",
        icon: ImageIcons.partnerWithUsMenu,
      },
      {
        link: "Home Internet Provider",
        type: "home-internet-provider",
        icon: ImageIcons.partnerWithUsMenu,
      },
      {
        link: "IPTV",
        type: "iptv",
        icon: ImageIcons.partnerWithUsMenu,
      },
      {
        link: "Free Wifi Hotspot",
        type: "free-wifi-hotspot",
        icon: ImageIcons.partnerWithUsMenu,
      },
      {
        link: "Managed Network",
        type: "managed-network",
        icon: ImageIcons.partnerWithUsMenu,
      },
      {
        link: "Cloud Cam",
        type: "https://www.mycloudcam.in/",
        icon: ImageIcons.partnerWithUsMenu,
      },
      {
        link: "VoIP Intercom",
        type: "voip-intercom",
        icon: ImageIcons.partnerWithUsMenu,
      },
      {
        link: "CDN Services",
        type: "cdn-services",
        icon: ImageIcons.partnerWithUsMenu,
      },

    ]
  },
  {
    title: "Pricing",
    subLinks: [
      {
        link: "Broadband",
        handle: "broadband"
      },
      {
        link: "Custom Plan",
        handle: "custom-plan"
      },
      {
        link: "OTT",
        handle: "ott"
      },
      {
        link: "Entertainment Combo",
        handle: "entertainment-combo"
      },
      {
        link: "Business Internet",
        handle: "business-internet"
      },
      {
        link: "Home Internet",
        handle: "home-internet"
      },
    ]
  },

];

export default Layout;
