import React, { useEffect } from 'react';
import Layout from '../components/shared/layout';
import Hero from "../components/home/hero";
// import BannerBoxs from '../components/home/bannerBoxs';
// import DreamInternet from '../components/home/dreamInternet';
// import Counting from '../components/home/counting';
import Services from '../components/home/services';
// import Testimonial from '../components/home/testimonial';
// import Investors from '../components/home/investors';
// import Expansion from '../components/home/expansion';
// import Plans from '../components/home/plans';
import axios from "axios"
// import FAQ from '../components/home/faq';
// import Blogs from '../components/home/blogs';
// import WeAreLocated from '../components/home/weAreLocated';
import SEO from '../components/shared/seo';
import DownloadApp from '../components/home/downloadApp';
import SuitablePlan from '../components/home/suitablePlan';
import SwitchMyInternet from '../components/home/switchMyInternet';

const Home = () => {

  useEffect(() => {
    async function testing() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URL}test`);
        console.log("response", response)

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    testing()
  }, [])
  return (
    <>
      <SEO title="Top Internet Service Providers | High-Speed Fiber & Broadband" description='Find the best internet service providers offering fast, high-speed fiber and broadband connections. Explore affordable packages and providers near you.' keywords="internet services provider,internet broadband service, internet broadband service provider, fast internet connection, high speed internet service provider, high speed internet connection, fiber internet packages, fiber internet providers, fiber internet Connection, high speed broadband services, high speed broadband connection, internet providers near me, fiber broadband, high speed broadband, high speed broadband, internet providers"
        schema={`
  {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    "name": "My Internet",
    "url": "https://www.myinternet.services/",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://www.myinternet.services/{search_term_string}",
      "query-input": "required name=search_term_string"
    }
  }
  `}
        
 business={`
    {         
       "@context": "https://schema.org/",         
       "@type": "LocalBusiness",         
       "@id": "#LocalBusiness",         
       "url": "https://www.myinternet.services/",          
       "legalName": "My Internet",
       "name": "My Internet",
       "description": "Find the best internet service providers offering fast, high-speed fiber and broadband connections. Explore affordable packages and providers near you.",
       "image": "https://www.myinternet.services/static/media/logo.c2c1f9387d45004a871b.webp",
       "logo": "https://www.myinternet.services/static/media/logo.c2c1f9387d45004a871b.webp",
       "telephone": "+91 9464001234",
       "email": "info@myinternet.services",
       "address": {             
         "@type": "PostalAddress",             
         "streetAddress": "B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055",             
         "addressLocality": "Mohali",             
         "addressRegion": "PB",             
         "addressCountry": "India",             
         "postalCode": "160055"        
        }
     }
 `} 
 

    organization={`
      {         
        "@context": "https://schema.org/",         
        "@type": "Organization",         
        "@id": "#Organization",         
        "url": "https://www.myinternet.services/",         
        "legalName": "My Internet",         
        "name": "My Internet",         
        "description": "Find the best internet service providers offering fast, high-speed fiber and broadband connections. Explore affordable packages and providers near you.",         
        "image": "https://www.myinternet.services/static/media/logo.c2c1f9387d45004a871b.webp",         
        "logo": "https://www.myinternet.services/static/media/logo.c2c1f9387d45004a871b.webp",         
        "telephone": "+91 9464001234",         
        "email": "info@myinternet.services",         
        "sameAs": [            
          "https://www.facebook.com/myinternetMohali/",          
          "https://www.youtube.com/@MyInternet_",             
          "https://www.instagram.com/myinternet__/",             
          "https://www.linkedin.com/company/my-internet/",           
          "https://x.com/MyInternet_"      
        ] 
    } 
    `}  

  faq={`
    {
    "@context": "https://schema.org/", 
    "@type": "FAQPage", 
    "mainEntity": [{
      "@type": "Question", 
      "name": "What is My Internet?",
      "acceptedAnswer": { 
        "@type": "Answer",
        "text": "My Internet service providers are a leading ISP. We offer high-speed, reliable and affordable services for both residential and commercial purposes."
      } 
    },{
      "@type": "Question", 
      "name": "How can I register for a new broadband connection?",
      "acceptedAnswer": { 
        "@type": "Answer",
        "text": "You need to select the log in section in the menu bar of our website, where you have to click on register yourself and after submitting the details, you will receive a call from our customer support team. Following this, you will be provided with plans and packages and then you can select a plan for yourself."
      }  
    
},{
"@type": "Question",
"name": "Do I need to provide any documentation for broadband connection?",
"acceptedAnswer": {
"@type": "Answer",
"text": "Yes, you need to provide your identity proof and your address."
}

},{
"@type": "Question",
"name": "How can I check fibre broadband connection availability in my area?",
"acceptedAnswer": {
"@type": "Answer",
"text": "For this, you need to fill in your details like name, contact number, city and installation address to register yourself. After this, you will receive a call from our customer support team and you can get your questions answered."
}

},{
"@type": "Question",
"name": "How much time does it take for an internet connection to get active?",
"acceptedAnswer": {
"@type": "Answer",
"text": "It usually takes 48 hours for an internet connection to get stabilised. If you experience any initial hiccups or slow speed at initial speed, there is no need to worry, as at the initial stage, the network optimises itself."
}

},{
"@type": "Question",
"name": "In what areas, the services of my internet are available?",
"acceptedAnswer": {
"@type": "Answer",
"text": "My Internet was launched in 2016 by providing internet services all over the Punjab. Now our services are available in more than 18 states and we have a community of 57000+ customers."
}

},{
"@type": "Question",
"name": "Do you offer any additional benefits?",
"acceptedAnswer": {
"@type": "Answer",
"text": "Yes users are offered with many discounts and benefits. One of the greatest advantages offered is that we provide our IPTV services free of cost with internet services subscription."
}

},{
"@type": "Question",
"name": "What are the charges of My Internet service providers?",
"acceptedAnswer": {
"@type": "Answer",
"text": "The charges are completely dependent upon the plan chosen and for this, you need to visit our website and check our plans."
}

},{
"@type": "Question",
"name": "Do I need to sign a contract?",
"acceptedAnswer": {
"@type": "Answer",
"text": "No there is no contract and users are provided with full liberty to cancel their subscription whenever they want."
}

},{
"@type": "Question",
"name": "Is it possible to change the plan?",
"acceptedAnswer": {
"@type": "Answer",
"text": "Yes absolutely, if you want to upgrade your plan, you can do it anytime."
}

},{
"@type": "Question",
"name": "Is it possible to schedule installation online?",
"acceptedAnswer": {
"@type": "Answer",
"text": "Yes through our website, you can book an online appointment by contacting our customer support service."
}

},{
"@type": "Question",
"name": "What equipment is required for getting internet connection installed?",
"acceptedAnswer": {
"@type": "Answer",
"text": "The modem and router are provided as a part of our plan. You are also allowed to use your own compatible equipment."
}

},{
"@type": "Question",
"name": "How can I contact the technical support team?",
"acceptedAnswer": {
"@type": "Answer",
"text": "Our customer support team is available round the clock, so you can contact them via phone, email or live chat options provided on our website"
}

},{
"@type": "Question",
"name": "What should I do in case I face issues in my internet connectivity?",
"acceptedAnswer": {
"@type": "Answer",
"text": "At first, you should restart your modem or router, however, if the problem persists, contact our customer support team."
}

},{
"@type": "Question",
"name": "At first, you should restart your modem or router, however, if the problem persists, contact our customer support team.",
"acceptedAnswer": {
"@type": "Answer",
"text": "It totally depends upon the size of your business, data usage and budget. You can contact our team and they will help you determine the best connection for you."
}

},{
"@type": "Question",
"name": "It totally depends upon the size of your business, data usage and budget. You can contact our team and they will help you determine the best connection for you.",
"acceptedAnswer": {
"@type": "Answer",
"text": "Yes, our plans are scalable, you can get them modified at any time."
}

},{
"@type": "Question",
"name": "How long can it take to set up a new internet connection?",
"acceptedAnswer": {
"@type": "Answer",
"text": "It is dependent on the type of connection and your location. We ensure swift services and installation."
}


    }]
}
  `} 

  blog1={`
  {
                  "@context": "https://schema.org",
                  "@type": "BlogPosting",
                  "mainEntityOfPage": {
                      "@type": "WebPage",
                      "@id": "https://www.myinternet.services/blogs/essential-features-in-broadband-apps-consumers-guide"
                  },
                  "headline": "Essential Features of Top Broadband Applications",
                  "image": [
                      "[object Object]"
                  ],
                  "datePublished": "2024-08-15",
                  "author": {
                      "@type": "Person",
                      "name": "Simran Kaur"
                  },
                  "publisher": {
                      "@type": "Organization",
                      "name": "My Internet",
                      "logo": {
                          "@type": "ImageObject",
                          "url": "https://res.cloudinary.com/dufxh7beq/image/upload/v1725264187/file_jnz0gd_bge5ri.png"
                      }
                  },
                  "description": "Get key features in high-speed internet, connectivity solutions, bandwidth management, and more for top-notch broadband applications and consumer satisfaction."
              }
  
  `}

 blog2={`
  {
                  "@context": "https://schema.org",
                  "@type": "BlogPosting",
                  "mainEntityOfPage": {
                      "@type": "WebPage",
                      "@id": "https://www.myinternet.services/blog/free-wifi-hotspots-malls-business-parks"
                  },
                  "headline": "Find Free WiFi Hotspots & WiFi Service Provider",
                  "image": [
                      "[object Object]"
                  ],
                  "datePublished": "2024-06-15",
                  "author": {
                      "@type": "Person",
                      "name": "Rakesh"
                  },
                  "publisher": {
                      "@type": "Organization",
                      "name": "My Internet",
                      "logo": {
                          "@type": "ImageObject",
                          "url": "https://res.cloudinary.com/dufxh7beq/image/upload/v1718627460/file_vqxwue_e7t7cj.png"
                      }
                  },
                  "description": "Find free WiFi hotspots for malls, business parks, and residential areas near you. Locate a reliable WiFi service provider for seamless connectivity."
              }
  
  `}
blog3={`
{
                "@context": "https://schema.org",
                "@type": "BlogPosting",
                "mainEntityOfPage": {
                    "@type": "WebPage",
                    "@id": "https://www.myinternet.services/blogs/how-to-choose-best-internet-service-provider"
                },
                "headline": "Best Internet Service Provider & Broadband Services",
                "image": [
                    "[object Object]"
                ],
                "datePublished": "2024-06-15",
                "author": {
                    "@type": "Person",
                    "name": "Vikesh Thakur"
                },
                "publisher": {
                    "@type": "Organization",
                    "name": "My Internet",
                    "logo": {
                        "@type": "ImageObject",
                        "url": "https://res.cloudinary.com/dufxh7beq/image/upload/v1725270125/file_q3zpho_yfwf3w.jpg"
                    }
                },
                "description": "The best internet service provider near you. Find reliable broadband services for your needs. Compare plans and prices conveniently."
            }
  `}
blog4={`
  {
                  "@context": "https://schema.org",
                  "@type": "BlogPosting",
                  "mainEntityOfPage": {
                      "@type": "WebPage",
                      "@id": "https://www.myinternet.services/blogs/choosing-the-best-internet-broadband-service-provider-in-2024"
                  },
                  "headline": "Reliable Internet Broadband Services & Providers",
                  "image": [
                      "[object Object]"
                  ],
                  "datePublished": "2024-09-02",
                  "author": {
                      "@type": "Person",
                      "name": "Simran Kaur"
                  },
                  "publisher": {
                      "@type": "Organization",
                      "name": "My Internet",
                      "logo": {
                          "@type": "ImageObject",
                          "url": "https://res.cloudinary.com/dufxh7beq/image/upload/v1725252734/file_nudy4t_gwax45.webp"
                      }
                  },
                  "description": "Top internet broadband service providers for fast, reliable connections. Find the best broadband service options to suit your needs and budget."
              }
  `}
          />

      <Layout>
        <Hero />
        {/* <BannerBoxs /> */}
        {/* <DreamInternet />
      <Counting />
      <WeAreLocated />
      <Plans /> */}
        {/* <Expansion /> */}
        {/* <Investors />
      <FAQ />
      <Testimonial />
      <Blogs /> */}
        <SwitchMyInternet />
        {/* <SuitablePlan /> */}
        <Services />
        <DownloadApp />
      </Layout>

    </>
  )
}
export default Home