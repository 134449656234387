import logo from "../../assets/images/logo.svg";
import mailIcon from "../../assets/icons/mail-icon.webp";
import sendIcon from "../../assets/icons/send-icon.webp";
import businessInternetBanner from "../../assets/images/business_internet.webp";
import enhanceInternetBanner from "../../assets/images/enhance_internet.webp";
import exclusiveOffersBanner from "../../assets/images/exclusive_offers.webp";
import myinternetAppBanner from "../../assets/images/myinternet_app.webp";
import globShape from "../../assets/images/glob-shape.webp";
import myinternetBanner from "../../assets/images/myinternet-banner.webp";
import dreamInternet from "../../assets/images/dreaminternet.webp";
import servicesBg from "../../assets/images/services-bg.webp";
import testimonialBg from "../../assets/images/testimonial-bg.webp";
import client1 from "../../assets/images/client/client1.webp";
import client2 from "../../assets/images/client/client2.webp";
import client3 from "../../assets/images/client/client3.webp";
import testimonialBanner from "../../assets/images/testimonial-banner.webp";
import investor from "../../assets/images/investor.webp";
import expansion from "../../assets/images/expansion.webp";
import plansBg from "../../assets/images/plans_bg.webp";
import contactBg from "../../assets/images/contact_bg.webp";
import ranjeetSingh from "../../assets/images/ranjeet_singh_sandhu.webp";
import inneraboutShape from "../../assets/images/inner-about-shape-img.webp";
import ceo from "../../assets/images/about/ceo.webp";
import cto from "../../assets/images/about/cto.webp";
import president from "../../assets/images/about/president.webp";
import leadershipBg from "../../assets/images/leadership_bg.webp";
import aboutShape from "../../assets/images/about-shape.webp";
import visionInternetImg from "../../assets/images/vision-internet-img.webp";
import visionInternet from "../../assets/images/vision-internet.webp";
import internetNecessity from "../../assets/images/internet-necessity.webp";
import investment from "../../assets/images/investment.webp";
import philanthropy from "../../assets/images/philanthropy.webp";
import broadband from "../../assets/images/services/broadband.webp";
import cdnServices from "../../assets/images/services/cdn-services.webp";
import cloudCam from "../../assets/images/services/cloud-cam.webp";
import iptv from "../../assets/images/services/iptv.webp";
import leaseLine from "../../assets/images/services/lease-line.webp";
import manahedNetwork from "../../assets/images/services/manahed_network.webp";
import voipIntercom from "../../assets/images/services/voip-intercom.webp";
import wifiHotspots from "../../assets/images/services/wifi-hotspots.webp";
import registerImg from "../../assets/images/register.webp";
import help from "../../assets/images/help.webp";
import theme1 from "../../assets/images/theme1.webp";
import theme2 from "../../assets/images/theme2.webp";
import theme3 from "../../assets/images/theme3.webp";
import theme4 from "../../assets/images/theme4.webp";
import theme5 from "../../assets/images/theme5.webp";
import ghostWhite from "../../assets/images/ghost-white.gif";
import jobOpening from "../../assets/images/job-opening.webp";
import hiring from "../../assets/images/hiring.webp";
import faqBg from "../../assets/images/faq_bg.webp";
import advantagesInternetLeased from "../../assets/images/advantages_internet_leased.webp";
import chooseLeasedLine from "../../assets/images/choose_leased_line.webp";
import cityImg from "../../assets/images/city_img.webp";
import unlimited from "../../assets/images/city/unlimited.webp";
import support from "../../assets/images/city/support.webp";
import speed from "../../assets/images/city/speed.webp";
import device from "../../assets/images/city/device.webp";
import insights from "../../assets/images/city/insights.webp";
import flexible from "../../assets/images/city/flexible.webp";
import franchiseeImg from "../../assets/images/franchisee_img.webp";
import Broadband from "../../assets/images/Broadband.webp";
import intertainment from "../../assets/images/intertainment.webp"
import Franchisee from "../../assets/images/Franchisee.webp";
import Aboutus from "../../assets/images/Aboutus.webp";
import Vision from "../../assets/images/Vision.webp";
import Career from "../../assets/images/Career.webp";
import Contactus from "../../assets/images/Contactus.webp";
import FAQs from "../../assets/images/FAQs.webp";
import PrivacyPolicy from "../../assets/images/PrivacyPolicy.webp";
import TermCondition from "../../assets/images/Term&Condition.webp";
import RefundPolicy from "../../assets/images/RefundPolicy.webp";
import Disclaimer from "../../assets/images/Disclaimer.webp";
import Cities from "../../assets/images/Cities.webp";
import Marker from "../../assets/images/marker.webp";
import entertainment from "../../assets/images/entertainment.webp"
import getMyInternet from "../../assets/images/get_my_internet.webp"
import downloadApp from "../../assets/images/download_app.webp"
import downloadBtn from "../../assets/images/download_btn.webp"
import suitablePlan from "../../assets/images/suitable_plan.webp"
import area from "../../assets/images/area.webp"
import switchMyinternetImg from "../../assets/images/switch_myinternet_img.webp"
import highSpeedIcon from "../../assets/icons/high_speed.webp"
import iptvIcon from "../../assets/icons/iptv.webp"
import leasedLineIcon from "../../assets/icons/leased_line.webp"
import supportIcon from "../../assets/icons/support.webp"
import uniformDataFlow from "../../assets/icons/uniform_data_flow.webp"
import unlimitedInternet from "../../assets/icons/unlimited_internet.webp"
import newConnection from "../../assets/icons/new_connection.svg"
import quickPay from "../../assets/icons/quick_pay.svg"
import checkFeasibility from "../../assets/icons/check_feasibility.svg"
import aboutUsMenu from "../../assets/icons/menus/about_us.svg"
import blogsMenu from "../../assets/icons/menus/blogs.svg"
import newConnectionMenu from "../../assets/icons/menus/new_connection.svg"
import ourServicesMenu from "../../assets/icons/menus/our_services.svg"
import partnerWithUsMenu from "../../assets/icons/menus/partner_with_us.svg"
import tariffPlansMenu from "../../assets/icons/menus/tariff_plans.svg"
import quickPayMenu from "../../assets/icons/menus/quick_pay.svg"
import myAccountMenu from "../../assets/icons/menus/my_account.svg"
import banner1 from "../../assets/images/banner1.webp"
import businessInternetSubMenu from "../../assets/icons/sub_menus/business_internet.svg"
import cloudCamSubMenu from "../../assets/icons/sub_menus/cloud_cam.svg"
import homeWifiSubMenu from "../../assets/icons/sub_menus/home_wifi.svg"
import hotspotSubMenu from "../../assets/icons/sub_menus/hotspot.svg"
import iptvSubMenu from "../../assets/icons/sub_menus/iptv.svg"
import leasedLineSubMenu from "../../assets/icons/sub_menus/leased_line.svg"
import managedNetworkSubMenu from "../../assets/icons/sub_menus/managed_network.svg"
import cdnSubMenu from "../../assets/icons/sub_menus/cdn.svg"
import voipIntercomSubMenu from "../../assets/icons/sub_menus/voip_intercom.svg"
import customPlanSubMenu from "../../assets/icons/sub_menus/custom_plan.svg"
import ottPlanSubMenu from "../../assets/icons/sub_menus/ott.svg"
import entertainmentComboPlanSubMenu from "../../assets/icons/sub_menus/entertainment_combo.svg"
import viewPlansButtomMenu from "../../assets/icons/view_plans_buttom_menu.svg"
import myAccountsButtomMenu from "../../assets/icons/my_accounts_buttom_menu.svg"
import freeIptvSubscriptionHeroIcon from "../../assets/icons/hero/free_iptv_subscription.svg"
import unlimitedDataHeroIcon from "../../assets/icons/hero/unlimited_data.svg"
import freeInstallationHeroIcon from "../../assets/icons/hero/free_installation.svg"
import billingPaymentHeroIcon from "../../assets/icons/hero/billing_payment.svg"
import customerSupportHeroIcon from "../../assets/icons/hero/customer_support.svg"
import manageAccountHeroIcon from "../../assets/icons/hero/manage_account.svg"
import hostingHeroIcon from "../../assets/icons/hero/hosting.svg"
import domainHeroIcon from "../../assets/icons/hero/domain.svg"
import cloudCamHeroIcon from "../../assets/icons/hero/cloud_cam.svg"
import wifiHeroIcon from "../../assets/icons/hero/wifi.svg"
import ottHeroIcon from "../../assets/icons/hero/ott.svg"
import iptvHeroIcon from "../../assets/icons/hero/iptv.svg"
import superfastinternet from "../../assets/images/superfast-internet.png"
import paymentsuccessful from "../../assets/images/payment-successful.png"
import paymentsuccessicon from "../../assets/images/payment-success-icon.png"

const ImageIcons = {
  Franchisee,
  Aboutus,
  superfastinternet,
  paymentsuccessful,
  Vision,
  paymentsuccessicon,
  logo,
  Career,
  Contactus,
  FAQs,
  PrivacyPolicy,
  TermCondition,
  RefundPolicy,
  Disclaimer,
  Cities,
  mailIcon,
  sendIcon,
  businessInternetBanner,
  enhanceInternetBanner,
  exclusiveOffersBanner,
  myinternetAppBanner,
  globShape,
  myinternetBanner,
  dreamInternet,
  servicesBg,
  testimonialBg,
  client1,
  client2,
  client3,
  testimonialBanner,
  investor,
  expansion,
  plansBg,
  contactBg,
  ranjeetSingh,
  inneraboutShape,
  ceo,
  cto,
  president,
  leadershipBg,
  aboutShape,
  visionInternetImg,
  visionInternet,
  internetNecessity,
  investment,
  philanthropy,
  broadband,
  cdnServices,
  cloudCam,
  iptv,
  leaseLine,
  manahedNetwork,
  voipIntercom,
  wifiHotspots,
  registerImg,
  help,
  theme1,
  theme2,
  theme3,
  theme4,
  theme5,
  ghostWhite,
  jobOpening,
  hiring,
  faqBg,
  advantagesInternetLeased,
  chooseLeasedLine,
  cityImg,
  unlimited,
  support,
  speed,
  device,
  insights,
  flexible,
  franchiseeImg,
  Broadband,
  Marker,
  intertainment,
  entertainment,
  getMyInternet,
  downloadApp,
  downloadBtn,
  suitablePlan,
  area,
  switchMyinternetImg,
  highSpeedIcon,
  iptvIcon,
  leasedLineIcon,
  supportIcon,
  uniformDataFlow,
  unlimitedInternet,
  newConnection,
  quickPay,
  checkFeasibility,
  aboutUsMenu,
  blogsMenu,
  newConnectionMenu,
  ourServicesMenu,
  partnerWithUsMenu,
  tariffPlansMenu,
  quickPayMenu,
  myAccountMenu,
  banner1,
  businessInternetSubMenu,
  cloudCamSubMenu,
  homeWifiSubMenu,
  hotspotSubMenu,
  iptvSubMenu,
  leasedLineSubMenu,
  managedNetworkSubMenu,
  cdnSubMenu,
  voipIntercomSubMenu,
  customPlanSubMenu,
  ottPlanSubMenu,
  entertainmentComboPlanSubMenu,
  viewPlansButtomMenu,
  myAccountsButtomMenu,
  freeIptvSubscriptionHeroIcon,
  unlimitedDataHeroIcon,
  freeInstallationHeroIcon,
  billingPaymentHeroIcon,
  customerSupportHeroIcon,
  manageAccountHeroIcon,
  hostingHeroIcon,
  domainHeroIcon,
  cloudCamHeroIcon,
  wifiHeroIcon,
  ottHeroIcon,
  iptvHeroIcon,

};

export default ImageIcons;