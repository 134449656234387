import React from 'react';
// import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
// import { Link } from 'react-router-dom';
import ImageIcons from '../imageComponent/ImageIcons';

const LeadershipTeam = () => {
    return (
        <section className="our_teem relative bg-[#fff] bg-cover bg-repeat bg-fixed after:content-[''] after:absolute after:w-full after:h-full after:top-0 after:inset-x-0 after:bg-[#3a2515d6]" style={{ backgroundImage: `url(${ImageIcons.leadershipBg})` }}>
        <div className="container !max-w-[1200px] relative z-10">
            <div className="md:mb-[50px] mb-[35px]  text-center">
                <h4 className="text-center text-[#f38432] font-medium mb-[15px] uppercase">Leadership Team</h4>
                <h2 className='text-white'>Group of Certified & Experienced</h2>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2 xl:gap-x-[30px] lg:gap-x-[20px] sm:gap-x-[30px] md:gap-y-[50px] gap-y-[40px] btn-holder sm:max-w-[unset] max-w-[360px] m-auto">
                {teamData.map((item, i) => (
                    <div key={i} className={`team_box relative duration-500 rounded-[5px] group flex flex-col  btn btn-2 hover-slide-down`}>
                        <figure className='relative bg-[#e6eaed] h-[420px] 2xl:h-[450px] lg:h-[420px] md:h-[500px] duration-500 overflow-hidden before:content before:absolute before:top-[0] before:left-[0] before:h-[100%] before:w-[100%] group-hover:before:bg-[#f3843236] before:z-10 rounded-tl-[30px] rounded-br-[30px]'>
                            <img
                                src={item.teamImage}
                                className='w-[100%] h-[100%] object-cover duration-500 group-hover:scale-[1.1]'
                                alt='team'
                                width=""
                                height=""
                            />
                            {/* <figcaption className='duration-500 flex justify-center absolute left-[0] translate-y-[-50%] top-[50%] tr right-[0] text-center gap-[10px] z-20 opacity-0 invisible group-hover:opacity-100  group-hover:visible'>
                                <Link to='#' className='w-[50px] h-[50px] text-[28px] rounded-[50%] bg-[#f38432] duration-500 text-[#fff] flex justify-center items-center hover:bg-[#000]' ><FaFacebookF /></Link>
                                <Link to='#' className='w-[50px] h-[50px] text-[28px] rounded-[50%] bg-[#f38432] duration-500 text-[#fff] flex justify-center items-center hover:bg-[#000]' ><FaTwitter /></Link>
                                <Link to='#' className='w-[50px] h-[50px] text-[28px] rounded-[50%] bg-[#f38432] duration-500 text-[#fff] flex justify-center items-center hover:bg-[#000]'><FaInstagram /></Link>
                            </figcaption> */}
                            <div className='boxAnimation '></div>
                        </figure>
                        <div className='team_title pt-[20px]'>
                            <div className='flex flex-row items-center'>
                                <h5 className='relative uppercase text-[#f38432] text-[18px] font-medium mb-[5px] green-line-heading after:!w-[50px] after:!ml-[15px]'>{item.designation}</h5  >
                            </div>
                            <h4 className='mb-0 font-medium text-white'>{item.title}</h4>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </section>
    )
}

const teamData = [
    {
        teamImage: ImageIcons.president,
        title: "Ranjeet Singh Sandhu",
        designation: "President Emeritus",
    },
    {
        teamImage: ImageIcons.ceo,
        title: "Navjot Singh Sandhu",
        designation: "CEO/MD",
    },
    {
        teamImage: ImageIcons.cto,
        title: "Dr. Pawanpreet Dhaliwal",
        designation: "CTO/Director",
    }
]

export default LeadershipTeam