import React from 'react'
import ImageIcons from '../imageComponent/ImageIcons';
import { Link } from 'react-router-dom';

const DownloadApp = () => {

  return (
    <section className="relative pb-0 bg-[linear-gradient(0deg,#F2F2F2_0%,#FBFBFD_100%)]">
      <div className='container'>
        <div className='sm:hidden text-center'>
          <h2 className='text-[#f38432] text-[24px] mb-[15px]'>Track Your Data in Real-Time Download the Ultimate Internet App Now!</h2>
          <h3 className='text-[20px] mb-[10px]'>Manage Services | Billing | Support</h3>
        </div>
        <div className='flex items-center'>
          <div className='lg:min-h-[300px] sm:w-[40%] w-[55%] md:-mr-[60px]'>
            <img className='object-cover' src={ImageIcons.downloadApp} alt='download App' />
          </div>
          <div className='sm:w-[calc(60%_+_60px)] w-[45%] text-center'>
            <div className='max-w-[750px] mx-auto lg:py-[20px]'>
              <h2 className='text-[#f38432] lg:text-[32px] md:text-[26px] text-[21px] xl:mb-[30px] mb-[15px] max-sm:hidden'>Track Your Data in Real-Time Download the Ultimate Internet App Now!</h2>
              <h3 className='font-bold xl:mb-[30px] mb-[18px] max-md:text-[18px] max-sm:hidden'>Manage Services | Billing | Support</h3>
              <Link className='inline-block' to='https://play.google.com/store/apps/details?id=app.customerportal.digitax' target='_blank'><img className='mx-auto xl:w-[300px] md:w-[200px] w-[150px]' src={ImageIcons.downloadBtn} alt='download Button' /></Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


export default DownloadApp