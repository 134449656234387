import React from 'react';
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import Button from '../components/shared/button';
import SEO from '../components/shared/seo';

const Vision = () => {
  return (
    <>
      <SEO title='Our Vision at My Internet Services' description='Get MyInternet vision for the future of internet services. Learn about our commitment to innovation, reliability, and customer satisfaction.' />
      <Layout>
        <InnerPageBanner title='Vision' innerPageBanner={ImageIcons.Vision} />
        <section className='lg:my-[25px] my-[15px] relative pb-[0]'>
          <div className='absolute w-[220px] left-[20px] bottom-[20px] animate-[animate-bounceTop_5s_infinite_ease-in-out_alternate] max-lg:opacity-70'>
            <img  className='object-cover rotate-180' src={ImageIcons.aboutShape} alt='Shape' />
          </div>
          <div className='container '>
            <div className='flex lg:flex-nowrap flex-wrap lg:gap-[50px] gap-[40px] items-center'>
              <div className='lg:w-[50%] max-lg:order-2 w-full lg:py-[50px] md:py-[40px] !py-0 relative ' >
                <div className='relative z-10'>
                  <h2 className='font-bold'><span className='text-[#f38432]'>Internet</span> a Necessity</h2>
                  <p>The Covid-19 pandemic has shown us clearly that the Internet is no longer a luxury, Rather, Internet access has become a basic necessity. </p>
                  <p>Internet is the future, we at India a diversified community of more than 1.4 billion people , 60% of the population in India is connected with internet and average person uses 6 hours of internet daily. India is the second biggest nation connected with internet.</p>
                  <p>Internet users are now growing by an average of more than one million new users every day, with all of the original ‘Next Billion Users’ now online.</p>
                </div>
              </div>
              <div className='md:min-h-[500px] max-lg:order-1 sm:min-h-[400px] md:mr-[25px] mr-[15px] min-h-[300px] relative lg:w-[50%] w-full after:content-[""] after:absolute after:rounded-[20px] md:after:top-[-25px] after:top-[-15px] md:after:right-[-25px] after:right-[-15px] after:w-[calc(100%_-_50%)] after:z-[-1] after: after:bg-[#f38432] after:h-full'>
                <img  className='object-cover h-full w-full absolute rounded-[20px]' src={ImageIcons.internetNecessity} alt='Internet Necessity' />
              </div>
            </div>
          </div>
        </section>

        <section className='relative overflow-hidden mt-[25px]'>
          <div className='absolute w-[250px] right-0 bottom-[20px] animate-[animate-bounceTop_5s_infinite_ease-in-out_alternate] max-lg:opacity-30 opacity-40'>
            <img  className='object-cover' src={ImageIcons.inneraboutShape} alt='Shape' />
          </div>
          <div className='container '>
            <div className='flex lg:flex-nowrap flex-wrap items-center'>
              <div className='md:min-h-[500px] sm:min-h-[400px] md:ml-[25px] ml-[15px] min-h-[330px] relative lg:w-[50%] w-full content-[""] after:absolute md:after:top-[-25px] after:top-[-15px] md:after:left-[-25px] after:left-[-15px] after:w-[calc(100%_-_80px)] after:z-[-1] after:animate-[auto-fill_5s_infinite_ease-in-out_alternate] after:rounded-[20px] after:bg-[#f38432] md:after:h-[calc(100%_+_50px)] after:h-[calc(100%_+_30px)]'>
                <img  className='object-cover h-full w-full absolute rounded-[20px]' src={ImageIcons.myinternetBanner} alt='Internet Broadband Service Providers' />
              </div>

              <div className='lg:w-[50%] w-full xl:pl-[50px] lg:pl-[40px] relative max-lg:pt-[50px]' >
                <div className='relative z-10 lg:max-w-[700px] max-w-full'>
                  <h2 className='font-bold text-[#f38432]'>Internet Broadband Service Providers</h2>

                  <p>MyInternet services providers are a leading ISP in India. We offer advanced and future-proof internet services for both residential and enterprise purposes.As we aim to create a smooth and safely connected world, we are dedicated to making internet services accessible in every corner of India.  Our commitment to provide quality services has always encouraged us to deliver customer-centric services with our cutting-edge infrastructure. We guarantee transforming your experience of using the internet. Join us today and enjoy our fast and reliable internet services.</p>

                  <Button hrefLink='/about-us' title='Learn More' />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='relative pt-0'>
          <div className='absolute w-[220px] left-[20px] top-[20px] max-lg:hidden animate-[animate-bounceTop_5s_infinite_ease-in-out_alternate] max-lg:opacity-70'>
            <img  className='object-cover rotate-180' src={ImageIcons.aboutShape} alt='Shape' />
          </div>
          <div className='container '>
            <div className='flex lg:flex-nowrap max-lg:flex-col-reverse flex-wrap lg:gap-[50px] gap-[30px] items-center'>
              <div className='lg:w-[50%] w-full lg:py-[50px] md:py-[40px] !py-0 relative ' >
                <div className='relative z-10'>
                  <h2 className='font-bold'><span className='text-[#f38432]'>Internet</span> a Necessity</h2>
                  <p>There are 4.93 billion people use the internet worldwide, 63.2% of the world population, 36.8 % of the population still do not have access to internet. India accounts for the 12 % of internet user globally.
                    The half of the world population don’t have access to internet, including half of the population in India, do not access to Internet, we at my Internet gave ourselves the target,we wish to provide internet to one and all at least in India by 2025, as we believe people who do not have access to internet lack the opportunity to improve their lives.</p>
                  <p>The half of the world population don’t have access to internet, including half of the population in India, do not access to Internet, we at my Internet gave ourselves the target,we wish to provide internet to one and all at least in India by 2025, as we believe people who do not have access to internet lack the opportunity to improve their lives.</p>
                </div>
              </div>
              <div className='md:min-h-[650px] sm:min-h-[500px] min-h-[330px] relative lg:w-[50%] w-full'>
                <img  className='object-contain h-full w-full absolute rounded-[20px]' src={ImageIcons.visionInternet} alt='Internet a Necessity' />
                <div className='w-full absolute left-[50%] top-[60%] -translate-x-2/4 -translate-y-2/4'>
                  <img className='object-contain m-auto w-[330px] max-xl:w-[250px] max-lg:w-[320px] max-md:w-[220px] max-sm:w-[180px]  animate-[animate-bounceTop_5s_infinite_ease-in-out_alternate]' src={ImageIcons.visionInternetImg} alt='Vision Internet' />
                </div>
              </div>
            </div>
          </div>
        </section>

      </Layout>
    </>
  )
}

export default Vision