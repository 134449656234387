import React from 'react'
import SEO from '../components/shared/seo'
import InnerPageBanner from '../components/shared/innerPageBanner'
import ImageIcons from '../components/imageComponent/ImageIcons';
import Layout from "../components/shared/layout";

const BillingPayment = () => {
  return (
    <div>
      <SEO title="Bill Payment | My Internet" description="" /> 
      <Layout>
        <InnerPageBanner title='Bill Payment' innerPageBanner={ImageIcons.Cities}/>

        {/* Bill Payment Section */}
        <section className='services relative'>
            <div className='container m-auto'>
                <div className='max-w-[530px] mx-auto rounded-[15px] shadow'>
                    <div className='px-[30px] pt-[30px]'>
                        <div className='mb-[30px]'>
                            <img src={ImageIcons.logo} className="2xl:max-h-[110px] xl:max-h-[90px] lg:max-h-[80px] max-h-[62px] mx-auto" alt='Logo' />
                        </div>
                        <h2 className='text-center text-[#f38432] md:text-[40px] md:text-[30px] mb-[30px]'>Bill Payment</h2>
                        <h4 className='text-center text-[24px] mb-[20px]'>Enter Your phone no.</h4>
                        <div className='mb-[15px]'>
                            <input className="w-full h-[50px] border border-[#D9D9D9] bg-[#fff] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee]"/>
                        </div>
                        <div className='text-center mb-[20px]'>
                            <button className="px-[45px] text-[18px] py-[12px] duration-500 text-[#fff] bg-[#f48533] rounded-[5px] hover:bg-[#000] ease inline-block" type="submit">Submit</button>
                        </div>
                    </div>
                    <div>
                        <img src={ImageIcons.superfastinternet} className="w-full" alt='Superfast Internet' />
                    </div>
                </div>
            </div>
        </section>

        {/* Detail Payment Section */}
        <section className='services relative'>
            <div className='container m-auto'>
                <div className='max-w-[530px] mx-auto rounded-[15px] shadow'>
                    <div className='px-[30px] pt-[30px] pb-[5px]'>
                        <div className='mb-[50px]'>
                            <img src={ImageIcons.logo} className="2xl:max-h-[110px] xl:max-h-[90px] lg:max-h-[80px] max-h-[62px] mx-auto" alt='Logo' />
                        </div>
                        <div className='relative rounded-[10px] shadow mb-[50px] px-[20px] pt-[20px] pb-[30px]'>
                            <h4 className='text-[24px] mb-[10px]'>Amit Kumar</h4>
                            <div className='grid md:grid-cols-2 max-md:grid-cols-1 md:items-center gap-[10px] mb-[15px]'>
                                <p className='font-medium text-[#000] text-[18px] m-0'>Plan: Demo_50</p>
                                <h3 className='md:text-right text-[34px] m-0'>₹ 3990.00/-</h3>
                            </div>
                            <p className='font-medium text-[14px] mb-0'><span className='pr-[10px]'>Bill generation Date</span><span className='text-[#8b8b8b]'>22/10/2024</span></p>
                            <div className='absolute right-[20px] bottom-[-24px]'>
                                <button className="font-bold px-[45px] text-[18px] py-[10px] duration-500 text-[#fff] bg-[#f48533] rounded-[5px] hover:bg-[#000] ease inline-block">Pay Now</button>
                            </div>
                        </div>
                        <div className='relative rounded-[10px] shadow mb-[50px] px-[20px] pt-[20px] pb-[30px]'>
                            <h4 className='text-[24px] mb-[10px]'>Amit Kumar</h4>
                            <div className='grid md:grid-cols-2 max-md:grid-cols-1 md:items-center gap-[10px] mb-[15px]'>
                                <p className='font-medium text-[#000] text-[18px] m-0'>Plan: Demo_50</p>
                                <h3 className='md:text-right text-[34px] m-0'>₹ 3990.00/-</h3>
                            </div>
                            <p className='font-medium text-[14px] mb-0'><span className='pr-[10px]'>Bill generation Date</span><span className='text-[#8b8b8b]'>22/10/2024</span></p>
                            <div className='absolute right-[20px] bottom-[-24px]'>
                                <button className="font-bold px-[45px] text-[18px] py-[10px] duration-500 text-[#fff] bg-[#f48533] rounded-[5px] hover:bg-[#000] ease inline-block">Pay Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* Payment Successful Section */}
        <section className='services relative'>
            <div className='container m-auto'>
                <div className='max-w-[530px] mx-auto rounded-[15px] shadow'>
                    <div className='px-[30px] py-[50px]'>
                        <div className='mb-[35px]'>
                            <img src={ImageIcons.paymentsuccessful} className="w-full max-w-[230px] mx-auto" alt='Payment Successful' />
                        </div>
                        <h4 className='font-semibold text-center text-[24px] md:mb-[50px] max-md:mb-[30px]'>Payment Successful</h4>
                        <div className='text-center'>
                            <button className="px-[45px] text-[18px] py-[12px] duration-500 text-[#fff] bg-[#f48533] rounded-[5px] hover:bg-[#000] ease inline-block">View receipt</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* Payment Successful Section */}
        <section className='services relative'>
            <div className='container m-auto'>
                <div className='max-w-[530px] mx-auto rounded-[15px] shadow'>
                    <div className='pt-[40px] mx-[5px] border-b-[2px] border-[#D9D9D9]'>
                        <div className='mb-[15px]'>
                            <img src={ImageIcons.paymentsuccessicon} className="w-full max-w-[38px] mx-auto" alt='Payment Successful' />
                        </div>
                        <h4 className='text-center text-[24px] mb-[30px]'>Payment Successful</h4>
                    </div>
                    <div className='max-w-[370px] mx-auto px-[15px] pt-[30px] md:pb-[50px] max-md:pb-[40px]'>
                        <h4 className='text-center text-[24px] mb-[30px]'>Transaction  Reciept</h4>
                        <div className='grid grid-cols-2 md:items-center gap-[10px] mb-[10px]'>
                                <p className='font-medium text-[#000] text-[18px] m-0'>Transaction Id:</p>
                                <p className='text-right text-[#8b8b8b] text-[18px] m-0'>2546331</p>
                        </div>
                        <div className='grid grid-cols-2 md:items-center gap-[10px] mb-[10px]'>
                                <p className='font-medium text-[#000] text-[18px] m-0'>Amount:</p>
                                <p className='text-right text-[#8b8b8b] text-[18px] m-0'>₹3999.00</p>
                        </div>
                        <div className='grid grid-cols-2 md:items-center gap-[10px] mb-[10px]'>
                                <p className='font-medium text-[#000] text-[18px] m-0'>Date:</p>
                                <p className='text-right text-[#8b8b8b] text-[18px] m-0'>05/11/2024</p>
                        </div>
                        <div className='grid grid-cols-2 md:items-center gap-[10px] mb-[10px]'>
                                <p className='font-medium text-[#000] text-[18px] m-0'>Payment method:</p>
                                <p className='text-right text-[#8b8b8b] text-[18px] m-0'>UPI</p>
                        </div>
                        <div className='text-center md:mt-[45px] max-md:mt-[30px]'>
                            <button className="px-[20px] text-[18px] py-[12px] duration-500 text-[#fff] bg-[#f48533] rounded-[5px] hover:bg-[#000] ease block w-full">Download</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

      </Layout>
    </div>
  )
}


export default BillingPayment
