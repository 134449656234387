import React from 'react'
import ImageIcons from '../imageComponent/ImageIcons';

const Message = () => {
    return (
        <section className='my-[25px] max-md:!mb-0 relative'>
            <div className='absolute w-[250px] right-0 bottom-[20px] animate-[animate-bounceTop_5s_infinite_ease-in-out_alternate] max-lg:opacity-30 opacity-80'>
                <img className='object-cover' src={ImageIcons.inneraboutShape} alt='Shape' />
            </div>
            <div className='container'>
                <div className='flex lg:flex-nowrap flex-wrap gap-[50px]'>
                    <div className='lg:min-h-[500px] sm:min-h-[600px] md:ml-[25px] ml-[15px] min-h-[400px] relative lg:w-[40%] w-full after:content-[""] after:absolute md:after:top-[-25px] after:top-[-15px] md:after:left-[-25px] after:left-[-15px] after:w-[calc(100%_-_80px)] after:z-[-1] after:animate-[auto-fill_5s_infinite_ease-in-out_alternate] after:rounded-[20px] after:bg-[#f38432] md:after:h-[calc(100%_+_50px)] after:h-[calc(100%_+_30px)]'>
                        <img className='object-cover object-top h-full w-full absolute rounded-[20px]' src={ImageIcons.ranjeetSingh} alt='Ranjeet Singh' />
                    </div>

                    <div className='lg:w-[60%] w-full lg:py-[50px] !py-0 relative' >
                        <div className='relative z-10'>
                            <h2 className='font-bold'><span className='text-[#f38432]'>President's</span> Message</h2>

                            <p>I am pleased to welcome you to myInternet. myInternet is changing the way customers think about internet & broadband in our state. We are also constantly striving to extend the reach, capabilities and service offerings of myInternet. myInternet is a company that is changing the face of internet for the state of Punjab through its safe, smart and efficient infrastructure. Something we have been doing consistently since last few months.</p>
                            <p>Our progressive thinking and creative approach is what makes us stand out from the crowd. It’s why we consistently win the trust and why so many of our clients keep coming back to us. We have a clear vision of what we need to deliver – high speed internet access with absolutely zero downtime ; and we guide our business using five core values – lead, grow, deliver, sustain and protect. I’m proud of the work we do and give you my personal commitment that we will deliver what we promise and do it safely and sustainably.</p>
                            <p>My Internet’s top priority has always been — and remains — providing high quality internet services and exceeding customer service expectations. myInternet’s experienced and professional technical and sales team is fully dedicated to delivering reliable, cost-effective and flexible solutions to customers. At myInternet, we will continue to enable our customers to expect more from us by enhancing our service offerings and meeting our customers’ evolving needs.</p>

                            <h4 className='font-medium mb-0'><span className='text-[20px] inline-block mb-[8px] font-normal'>Yours Sincerely,</span><br></br> RANJIT SINGH SANDHU</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Message