import React from 'react'
import ImageIcons from '../imageComponent/ImageIcons';

const SwitchMyInternet = () => {

  return (
    <section>
      <div className='container mx-auto'>
        <div className='md:mb-[50px] max-md:mb-[40px] max-sm:mb-[30px]'>
          <img  className='xl:w-[430px] lg:w-[380px] sm:w-[300px] w-[250px] mx-auto xl:mb-[25px] sm:mb-[20px] mb-[15px]' src={ImageIcons.intertainment} alt='Intertainment' />
          <h1 className='text-center font-normal xl:text-[32px] lg:text-[30px] md:text-[28px] sm:text-[26px] text-[22px]'>India's Trusted Choice for the Fastest Internet Provider</h1>
        </div>

        <div className='flex justify-center items-center'>
          <div className='relative z-10 xl:w-[calc(30%_+_100px)] lg:w-[calc(30%_+_90px)] sm:w-[calc(30%_+_70px)] w-[calc(30%_+_40px)]'>
            <div className='flex justify-end items-center lg:gap-[35px] max-lg:gap-[15px] max-sm:gap-[7px] xl:mb-[90px] lg:mb-[70px] sm:mb-[50px] mb-[25px]'>
              <h4 className='xl:text-[26px] lg:text-[22px] sm:text-[20px] text-[12px] font-medium mb-0 text-right'>High<br />Speed broadband</h4>
              <span className='xl:w-[88px] lg:w-[80px] sm:w-[70px] w-[35px] min-w-[35px]'>
                <img src={ImageIcons.highSpeedIcon} alt='' />
              </span>
            </div>
            <div className='flex justify-end items-center lg:gap-[35px] max-lg:gap-[15px] max-sm:gap-[7px] xl:mb-[90px] lg:mb-[70px] sm:mb-[50px] mb-[25px] xl:pr-[65px] sm:pr-[50px] pr-[30px]'>
              <h4 className='xl:text-[26px] lg:text-[22px] sm:text-[20px] text-[12px] font-medium mb-0 text-right'>Unlimited<br />Internet</h4>
              <span className='xl:w-[88px] lg:w-[80px] sm:w-[70px] w-[35px] min-w-[35px]'>
                <img src={ImageIcons.unlimitedInternet} alt='' />
              </span>
            </div>
            <div className='flex justify-end items-center lg:gap-[35px] max-lg:gap-[15px] max-sm:gap-[7px]'>
              <h4 className='xl:text-[26px] lg:text-[22px] sm:text-[20px] text-[12px] font-medium mb-0 text-right'>IPTV</h4>
              <span className='xl:w-[88px] lg:w-[80px] sm:w-[70px] w-[35px] min-w-[35px]'>
                <img  src={ImageIcons.iptvIcon} alt='' />
              </span>
            </div>
          </div>

          <div className='xl:-mx-[100px] lg:-mx-[90px] sm:-mx-[70px] -mx-[40px] w-[40%]'>
            <img src={ImageIcons.switchMyinternetImg} alt='Switch Myinternet' className='max-w-full' />
          </div>

          <div className='relative z-10 xl:w-[calc(30%_+_100px)] lg:w-[calc(30%_+_90px)] sm:w-[calc(30%_+_70px)] w-[calc(30%_+_40px)]'>
            <div className='flex items-center lg:gap-[35px] max-lg:gap-[15px] max-sm:gap-[7px] xl:mb-[90px] lg:mb-[70px] sm:mb-[50px] mb-[25px]'>
              <span className='xl:w-[88px] lg:w-[80px] sm:w-[70px] w-[35px] min-w-[35px]'>
                <img  src={ImageIcons.supportIcon} alt='Support' />
              </span>
              <h4 className='xl:text-[26px] lg:text-[22px] sm:text-[20px] text-[12px] font-medium mb-0'>24x7<br />Support</h4>
            </div>
            <div className='flex items-center lg:gap-[35px] max-lg:gap-[15px] max-sm:gap-[7px] xl:mb-[90px] lg:mb-[70px] sm:mb-[50px] mb-[25px] xl:pl-[65px] sm:pl-[50px] pl-[30px]'>
              <span className='xl:w-[88px] lg:w-[80px] sm:w-[70px] w-[35px] min-w-[35px]'>
                <img  src={ImageIcons.uniformDataFlow} alt='Uniform Data Flow' />
              </span>
              <h4 className='xl:text-[26px] lg:text-[22px] sm:text-[20px] text-[12px] font-medium mb-0'>Uniform<br />Data Flow</h4>
            </div>
            <div className='flex items-center lg:gap-[35px] max-lg:gap-[15px] max-sm:gap-[7px]'>
              <span className='xl:w-[88px] lg:w-[80px] sm:w-[70px] w-[35px] min-w-[35px]'>
                <img  src={ImageIcons.leasedLineIcon} alt='Leased Line' />
              </span>
              <h4 className='xl:text-[26px] lg:text-[22px] sm:text-[20px] text-[12px] font-medium mb-0'>leased<br />Line</h4>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default SwitchMyInternet